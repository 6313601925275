export default {
  methods: {
    scrollToElement(e) {
      if (e === 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      let id = e.target.hash;
      id = id.slice(1);
      const yOffset = window.innerWidth > 992 ? -100 : -65; // -100 desktop : -65 moblie
      const el = document.getElementById(id);
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });

      this.closeAllModals()
    }
  }
}
