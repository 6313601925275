<template>
  <section id="sliderRev">
    <div class="container">
      <h2 class="title">{{ $filters.localizeFilter('rev_title') }}</h2>
    </div>
    <div class="container-fluid sliderRev__cont" v-if="swiperDate">
      <swiper ref="mySwiper" class="sliderRev__slider"
        :key="swiperDate"
        @swiper="onSwiper"
        @slideChange="swiperChange">
        <swiper-slide v-for="(item, index) in reviews" :key="index" somedata>
          <div class="rev__item">
            <a v-if="item.link" :href="item.link" class="rev__fb">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.5798 31.7917C18.4465 31.8135 18.3127 31.8335 18.1785 31.8518C18.3127 31.8335 18.4465 31.8135 18.5798 31.7917Z"
                  fill="#91A0EB"
                />
                <path
                  d="M18.8999 31.7371C18.8362 31.7488 18.7725 31.7595 18.7087 31.7705C18.7725 31.7595 18.8362 31.7488 18.8999 31.7371Z"
                  fill="#91A0EB"
                />
                <path
                  d="M17.821 31.8962C17.665 31.9138 17.5081 31.9294 17.3506 31.9426C17.5081 31.9294 17.665 31.9138 17.821 31.8962Z"
                  fill="#91A0EB"
                />
                <path
                  d="M18.1284 31.8586C18.0535 31.8687 17.978 31.8779 17.9028 31.8867C17.978 31.8779 18.0535 31.8687 18.1284 31.8586Z"
                  fill="#91A0EB"
                />
                <path
                  d="M19.3354 31.6506C19.2793 31.6626 19.2229 31.6746 19.1665 31.686C19.2229 31.6746 19.2793 31.6626 19.3354 31.6506Z"
                  fill="#91A0EB"
                />
                <path
                  d="M20.4165 31.3821C20.3711 31.395 20.3257 31.4072 20.2803 31.4199C20.3257 31.4072 20.3711 31.395 20.4165 31.3821Z"
                  fill="#91A0EB"
                />
                <path
                  d="M20.0896 31.4714C20.0403 31.4844 19.9907 31.4976 19.9412 31.51C19.9907 31.4976 20.0403 31.4846 20.0896 31.4714Z"
                  fill="#91A0EB"
                />
                <path
                  d="M19.6619 31.5784C19.6096 31.5906 19.5569 31.6021 19.5042 31.6138C19.5569 31.6021 19.6096 31.5906 19.6619 31.5784Z"
                  fill="#91A0EB"
                />
                <path
                  d="M17.3455 31.9431C17.2612 31.9502 17.177 31.9565 17.0925 31.9622C17.177 31.9565 17.2612 31.9502 17.3455 31.9431Z"
                  fill="#91A0EB"
                />
                <path
                  d="M32 16C32 7.16479 24.8352 0 16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C16.094 32 16.1875 31.998 16.2812 31.9963V19.541H12.8438V15.5349H16.2812V12.5869C16.2812 9.16797 18.3684 7.30713 21.4182 7.30713C22.8787 7.30713 24.134 7.41602 24.5 7.4646V11.0378H22.397C20.7378 11.0378 20.4165 11.8264 20.4165 12.9834V15.5349H24.3838L23.8667 19.541H20.4165V31.3818C27.1042 29.4646 32 23.3032 32 16Z"
                  fill="#91A0EB"
                />
                <path
                  d="M17.0571 31.9644C16.8906 31.9751 16.7234 31.9836 16.5557 31.9893C16.7234 31.9836 16.8906 31.9751 17.0571 31.9644Z"
                  fill="#91A0EB"
                />
                <path
                  d="M16.5471 31.9897C16.4587 31.9927 16.3701 31.9949 16.2815 31.9963C16.3701 31.9949 16.4587 31.9927 16.5471 31.9897Z"
                  fill="#91A0EB"
                />
              </svg>
            </a>
            <img v-if="item.ava" :src="item.ava" alt="avatar" class="rev__ava" />
            <img v-else src="@/assets/default-avatarl.jpg" alt="avatar" class="rev__ava" />
            <h5 class="rev__title v2">{{ item.name }}</h5>
            <p class="rev__text1" v-html="item.review[lang]"></p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="container" v-if="width < 768">
      <SwiperPagination
        class="sliderRev__pagination"
        :currentSlide="swiperPaginationOptions.currentSlide"
        :allSlides="swiperPaginationOptions.allSlides"
        :shiftSlides="swiperPaginationOptions.shiftSlides"
        @ChangeSlide="swiperSlideTo($event)"
      />
      <button class="btn-orange slideRev__btn" @click="goToEvents">{{ $filters.localizeFilter('reg') }}</button>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperPagination from '@/components/SwiperPagination';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const ReviewRepository = RepositoryFactory.get('review');

export default {
  name: 'sliderRev',
  data() {
    return {
      swiper: null,
      swiperPaginationOptions: null,
      swiperDate: Date.now(),
      width: window.innerWidth,
      reviews: []
    };
  },
  methods: {
    onSwiper: function(swiper) {
      this.swiper = swiper;
      this.setAdaptiveSwiperOptions();
      this.getData();
    },
    goToEvents: function () {
      if(this.$store.state.isDev) return this.launchModal('modal__reg')
      else location.href='//affiliate.traforce.com/v2/sign/up'
    },
    swiperChange() {
      const instance = this.swiper;
      this.swiperPaginationOptions.currentSlide = +instance.realIndex;
      this.swiperPaginationOptions.allSlides = +instance.slides.length;
    },
    swiperSlideTo(slideTo) {
      this.swiper.slideTo(slideTo, 400);
    },
    setAdaptiveSwiperOptions() {
      const w = window.innerWidth;
      let options;
      let optionsPagination;

      if (w >= 1200) {
        options = {
          slidesPerView: 5,
          spaceBetween: 50
        };
        optionsPagination = {
          shiftSlides: 1
        };
      } else if (w >= 992) {
        options = {
          slidesPerView: 4,
          spaceBetween: 30
        };
      } else if (w >= 768) {
        options = {
          slidesPerView: 3,
          spaceBetween: 30
        };
      } else if (w >= 576) {
        options = {
          slidesPerView: 2,
          spaceBetween: 30
        };
      } else if (w >= 500) {
        options = {
          slidesPerView: 1,
          spaceBetween: 20
        };
      } else if (w >= 400) {
        options = {
          slidesPerView: 1,
          spaceBetween: -45
        };
      } else if (w >= 350) {
        options = {
          slidesPerView: 1,
          spaceBetween: -60
        };
      } else {
        options = {
          slidesPerView: 1,
          spaceBetween: -55
        };
        optionsPagination = {
          shiftSlides: 0
        };
      }

      // set data in reactive variables
      // this.swiperOptions = {
      //   autoplay: {
      //     delay: 8000
      //   },
      //   loop: true,
      //   ...options
      // };
      options = Object.assign({}, options, {
        autoplay: {
          delay: 8000
        },
        loop: true
        // ...options
      });
      this.swiper.params = Object.assign({}, this.swiper.params, { ...options });

      this.swiperPaginationOptions = {
        currentSlide: 0,
        allSlides: 10,
        ...optionsPagination
      };
    },
    async getData () {
      try { 
        const res = await ReviewRepository.get()
        this.SetReviews(res.data)
      } catch (error) {
        return false;
      }
    },
    SetReviews (data) {
      if (!data) return false
      this.reviews = []
    
      data.filter(item => {
        let partner = {
          name: item.partner_name,
          descr: '',
          review: {
            ru: item.message,
            en: '',
            ua: ''
          }
        }
        if (item.partner_avatar) partner.ava = `/${item.partner_avatar}`
        if (item.translations)
          item.translations.filter(translation => { partner.review[translation.locale] = translation.value })

        this.reviews.push(partner)
      })
    }
  },
  computed: {
    swiperCurrentSlide() {
      return this.swiper.activeIndex;
    },
    swiperAllSlides() {
      return this.swiper.slides;
    },
    lang() {
      return this.$store.state.lang;
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    SwiperPagination
  }
};
</script>
<style lang="scss" scoped>
#sliderRev {
  padding-bottom: 80px;
  overflow-x: hidden;
  .title {
    margin-bottom: 0px;
  }
}

.sliderRev__cont {
  // padding-left: calc(50% - (1170px / 2) - 150px);
  position: relative;
  // right: -150px;
  // slider
  .sliderRev__slider {
    padding: 40px 20px;
    padding-right: 0px;
    margin-right: -60px;
    // item
    .swiper-slide {
    }
  }
}
// inside of item of slider
.rev__item {
  padding: 16px 15px 16px 27px;
  box-sizing: border-box;
  background: linear-gradient(138.8deg, #e9eff7 13.49%, #e2e8f0 97.05%);
  box-shadow: 14px 10px 28px #bccadc, -14px -10px 28px #f3f4f7;
  border-radius: 9px;
  .rev__fb {
    width: 100%;
    box-sizing: border-box;
    svg {
      display: block;
      &:hover path {
        fill: #1877f2;
      }
    }
  }
  .rev__ava {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  .rev__title {
    font: bold 1rem/1.4 $font;
    color: $grey2;
    &.v2 {
      margin-bottom: 13px;
    }
  }
  .rev__descr {
    font: 600 0.87rem/1.185 $font;
    color: $grey;
    margin-bottom: 13px;
  }
  .rev__text1 {
    font: 0.87rem/1.25 $font;    color: $grey2;
  }
}
.slideRev__btn {
  margin: 0 auto;
}
@media (max-width: 1200.98px) {
  .sliderRev__cont {
    padding-left: calc(50% - (1170px / 2) - 50px);
    right: -50px;
    // slider
    .sliderRev__slider {
      padding: 40px 20px;
      padding-right: 0px;
      margin-right: -60px;
      // item
      .swiper-slide {
      }
    }
  }
}
@media (max-width: 992.98px) {
  #sliderRev {
    .title {
      text-align: center;
    }
  }
  .sliderRev__cont {
    padding-left: 15px;
    right: 0px;
    // slider
    .sliderRev__slider {
      padding: 30px 15px;
      margin-right: -20px;
      // item
      .swiper-slide {
      }
    }
  }
  // inside of item of slider
  .rev__item {
    padding: 16px 15px 16px 27px;
    .rev__fb {
      margin-bottom: -10px;
      svg {
        margin-left: auto;
      }
    }
    .rev__ava {
      width: 60px;
      height: 60px;
      margin-bottom: 15px;
    }
    .rev__title {
      font: bold 1rem/1.4 $font;
    }
    .rev__descr {
      font: 600 0.87rem/1.185 $font;
      margin-bottom: 13px;
    }
    .rev__text1 {
      font: 0.87rem/1.25 $font;    }
  }}
@media (max-width: 768.98px) {  .sliderRev__pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
}
@media (max-width: 576.98px) {
  #sliderRev {
    padding-bottom: 35px;
  }
  .sliderRev__cont {
    width: calc(100% + 0px);
    padding-left: 0;
    position: relative;
    right: 0px;
    // slider
    .sliderRev__slider {
      padding-right: 0px;
      padding-bottom: 33px;
      margin-right: -15px;
      // item
      .swiper-slide {
      }
    }
  }
  // inside of item of slider
  .rev__item {
    width: 240px;
    width: 320px;
    padding: 10px;
    .rev__fb {
      margin-bottom: -30px;
      svg {
        margin-left: auto;
      }
    }
    .rev__ava {
      margin-bottom: 15px;
    }
    .rev__title {
      font: bold 1rem/1.4 $font;
    }
    .rev__descr {
      font: 600 0.87rem/1.185 $font;
      margin-bottom: 13px;
    }
    .rev__text1 {
      font: 0.87rem/1.25 $font;
    }
  }
}
@media (max-width: 500.98px) {
  // inside of item of slider
  .rev__item {
    width: 340px;
  }
}
@media (max-width: 400.98px) {
  // inside of item of slider
  .rev__item {
    width: 270px;
  }
}
@media (max-width: 350.98px) {
  // inside of item of slider
  .rev__item {
    width: 240px;
  }
}
</style>
