<template>
	<div class="modal__container-reg" :class="{ step1: formSteps === 1, step2: formSteps === 2, finish: formSteps === 3, hz: true }">
		<b-modal
			id="modal__reg"
			ref="modal__reg"
			v-model="this.$store.state.isModalOpen"
			v-if="this.$store.state.isModalOpen == true"
			:modal-class="{ step1: formSteps === 1, step2: formSteps === 2, finish: formSteps === 3, hz: true }"
			@hidden="this.$store.state.isModalOpen = false;"
			hide-footer hide-header>
			<div class="modal__content">
				<form class="modal__form" @submit.prevent="submitForm">
					<!-- STEP 1 -->
					<div v-if="formSteps === 1" class="modal__step one">
						<h4 class="modal__title"  v-text="this.$filters.localizeFilter('regmodal_title')"></h4>

						<!-- email -->
						<input
							type="email"
							name="email"
							:placeholder="$filters.localizeFilter('regmodal_email_placeholder')"
							class="modal__input-email modal__input"
							v-model.trim="v$.email.$model"
							:class="{
								invalid: (v$.email.$dirty && !v$.email.required) || (v$.email.$dirty && !v$.email.email),
								validated: v$.email.$dirty && v$.email.required && v$.email.email
							}"
						/>
						<p class="validation-error" v-if="v$.email.$dirty && !v$.email.required">
							{{ $filters.localizeFilter('regmodal_email_error_required') }}
						</p>
						<p class="validation-error" v-if="v$.email.$dirty && !v$.email.email">
							{{ $filters.localizeFilter('regmodal_email_error_required2') }}
						</p>

						<!-- password -->
						<div class="modal__pasword">
							<div class="modal__password-cont">
								<input
									type="password"
									name="password"
									:placeholder="$filters.localizeFilter('regmodal_pass_placeholder')"
									class="modal__input-password modal__input"
									v-model.trim="v$.password.$model"
									:class="{
										invalid: v$.password.$dirty && !v$.password.strongPassword,
										validated: v$.password.$dirty && v$.password.strongPassword
									}"
								/>
							</div>
							<!-- repeat password -->
							<div class="modal__password-cont">
								<input
									type="password"
									name="password-check"
									:placeholder="$filters.localizeFilter('regmodal_pass2_placeholder')"
									class="modal__input-password-check modal__input"
									v-model.trim="v$.repeatPassword.$model"
									:class="{
										invalid: !v$.repeatPassword.sameAsPassword,
										validated: v$.repeatPassword.sameAsPassword && repeatPassword.length && v$.password.strongPassword
									}"
								/>
							</div>
						</div>
						<p class="modal__text1" :class="{ invalid: v$.password.$dirty && !v$.password.strongPassword }">
							{{ $filters.localizeFilter('regmodal_pass_error') }}
						</p>

						<!-- messanger -->
						<div class="modal__select-cont">
							<select
								name="messanger"
								class="modal__select-messanger modal__input"
								v-model="v$.messanger.$model"
								:class="{
									invalid: v$.messanger.$dirty && !v$.messanger.required,
									validated: v$.messanger.$dirty && v$.messanger.required
								}"
							>
								<option disabled value="">{{ $filters.localizeFilter('regmodal_messanger_0') }}</option>
								<option value="Telegram">{{ $filters.localizeFilter('regmodal_messanger_1') }}</option>
								<option value="Skype">{{ $filters.localizeFilter('regmodal_messanger_2') }}</option>
							</select>
						</div>
						<!-- user login -->
						<input
							type="text"
							name="messanger_login"
							:placeholder="$filters.localizeFilter('regmodal_login_placeholder')"
							class="modal__input-login-messanger modal__input"
							v-model="v$.loginMessanger.$model"
							:class="{
								invalid: v$.loginMessanger.$dirty && !v$.loginMessanger.required,
								validated: v$.loginMessanger.$dirty && v$.loginMessanger.required
							}"
						/>
						<p class="modal__text1" :class="{ invalid: v$.loginMessanger.$dirty && !v$.loginMessanger.isvalid }">
							{{ $filters.localizeFilter('regmodal_messanger_error') }}
						</p>

						<!-- login of target manager -->
						<div class="modal__btn-cont">
							<vue-recaptcha
								ref="recaptcha"
								size="invisible"
								:sitekey="sitekey"
								@verify="validStep1"
								@expired="onCaptchaExpired"
							/>
							<button type="button" class="btn-orange modal__btn" @click="nextStep('next')">
								<span v-if="!formLoad">{{ $filters.localizeFilter('regmodal_capcha_next') }}</span>
								<span v-else style="display:flex;align-items: center;">
									<img src="@/assets/unnamed.gif" alt="" width="20px" style="display: inline-block;margin-right:6px;">
									<span style="color: white;">{{ $filters.localizeFilter('regmodal_capcha_loading') }}</span>
								</span>
							</button>
						</div>
					</div>

					<!-- STEP 2 -->
					<div v-else-if="formSteps === 2" class="modal__step two">
						<h4 class="modal__title">
							{{ $filters.localizeFilter('regmodal_title_step2') }}
						</h4>

						<!-- 1 -->
						<div class="modal__select-cont">
							<select
								name="experience"
								class="modal__select-exp modal__input"
								v-model="experience"
								@change="v$.experience.$touch()"
								:class="{
									invalid: v$.experience.$dirty && !v$.experience.required,
									validated: v$.experience.$dirty && v$.experience.required
								}"
							>
								<option disabled value="">{{ $filters.localizeFilter('regmodal_experience_0') }}</option>
								<option value="1">{{ $filters.localizeFilter('regmodal_experience_1') }}</option>
								<option value="2">{{ $filters.localizeFilter('regmodal_experience_2') }}</option>
								<option value="3">{{ $filters.localizeFilter('regmodal_experience_3') }}</option>
								<option value="4">{{ $filters.localizeFilter('regmodal_experience_4') }}</option>
								<option value="5">{{ $filters.localizeFilter('regmodal_experience_5') }}</option>
							</select>
						</div>
						<!-- 2 * -->
						<p class="modal__text2" :class="{ invalid: v$.typeTrafficValid.$dirty && !v$.typeTrafficValid.checked }">
							{{ $filters.localizeFilter('regmodal_traffic_label') }}
						</p>
						<div class="modal__list">
							<label v-for="(item, index) of typeTraffic" :key="index">
								<input type="checkbox" name="type_of_traffic" :checked="item.isChecked" :value="item.key" v-model="item.isChecked" />
								<span>
									<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M3.44626 7.8621C3.36035 7.94851 3.24314 7.99673 3.12138 7.99673C2.99963 7.99673 2.88242 7.94851 2.79651 7.8621L0.201944 5.26711C-0.0673148 4.99785 -0.0673148 4.56123 0.201944 4.29248L0.526822 3.96752C0.796165 3.69826 1.23228 3.69826 1.50154 3.96752L3.12138 5.58745L7.49844 1.21031C7.76779 0.941047 8.20432 0.941047 8.47316 1.21031L8.79804 1.53527C9.0673 1.80453 9.0673 2.24106 8.79804 2.5099L3.44626 7.8621Z"
											fill="white"
										/>
									</svg>
									{{ item.name }}
								</span>
							</label>
							<input
								type="text"
								class="modal__text-field modal__input"
								:class="{
									invalid: v$.typeTrafficValid.$dirty && !v$.typeTrafficValid.inputValid,
									validated: v$.typeTrafficValid.$dirty && v$.typeTrafficValid.inputValid
								}"
								v-if="typeTraffic[typeTraffic.length - 1].isChecked"
								v-model="typeTraffic[typeTraffic.length - 1].other"
								@input="v$.typeTrafficValid.$touch()"
							/>
						</div>

						<!-- 3 * -->
						<p class="modal__text2" :class="{ invalid: v$.from.$dirty && !v$.from.checked }">{{ $filters.localizeFilter('regmodal_from1_title') }}</p>
						<div class="modal__list modal__list-with-text-field">
							<label v-for="(item, index) of from" :key="index + 999">
								<input type="radio" name="knew_from" :value="item.isChecked" :checked="item.isChecked" @input="setCheckedObject(index)" />
								<span>{{ item.name }}</span>
								<input
									type="text"
									class="modal__text-field modal__input"
									:class="{
										invalid: v$.from.$dirty && !v$.from.inputValid,
										validated: v$.from.$dirty && v$.from.inputValid
									}"
									v-if="item.isChecked"
									v-model="item.source"
									:placeholder="item.placeholder"
									@input="v$.from.$touch()"
								/>
							</label>
						</div>

						<!-- agree -->
						<label class="modal__agree" :class="{ invalid: v$.agree1.$dirty && !v$.agree1.required }">
							<input type="checkbox" name="agree_1" v-model="v$.agree1.$model" />
							<span>
								<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M3.44626 7.8621C3.36035 7.94851 3.24314 7.99673 3.12138 7.99673C2.99963 7.99673 2.88242 7.94851 2.79651 7.8621L0.201944 5.26711C-0.0673148 4.99785 -0.0673148 4.56123 0.201944 4.29248L0.526822 3.96752C0.796165 3.69826 1.23228 3.69826 1.50154 3.96752L3.12138 5.58745L7.49844 1.21031C7.76779 0.941047 8.20432 0.941047 8.47316 1.21031L8.79804 1.53527C9.0673 1.80453 9.0673 2.24106 8.79804 2.5099L3.44626 7.8621Z"
										fill="white"
									/>
								</svg>
								{{ $filters.localizeFilter('regmodal_agree') }} <a href="//affiliate.traforce.com/v2/terms" target="_blank">{{ $filters.localizeFilter('regmodal_terms') }}</a></span
							>
						</label>

						<label class="modal__agree" :class="{ invalid: v$.agree2.$dirty && !v$.agree2.required }">
							<input type="checkbox" name="agree_2" v-model="v$.agree2.$model" />
							<span>
								<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M3.44626 7.8621C3.36035 7.94851 3.24314 7.99673 3.12138 7.99673C2.99963 7.99673 2.88242 7.94851 2.79651 7.8621L0.201944 5.26711C-0.0673148 4.99785 -0.0673148 4.56123 0.201944 4.29248L0.526822 3.96752C0.796165 3.69826 1.23228 3.69826 1.50154 3.96752L3.12138 5.58745L7.49844 1.21031C7.76779 0.941047 8.20432 0.941047 8.47316 1.21031L8.79804 1.53527C9.0673 1.80453 9.0673 2.24106 8.79804 2.5099L3.44626 7.8621Z"
										fill="white"
									/>
								</svg>
								{{ $filters.localizeFilter('regmodal_agree_description') }}
							</span>
						</label>

						<div class="modal__btn-cont">
							<button type="button" class="btn-white modal__btn mr-3" @click="setStep(1)">
								<img src="@/assets/left-chevron.svg" height="14px">
							</button>
							<vue-recaptcha
								ref="recaptcha"
								size="invisible"
								:sitekey="sitekey"
								@verify="regUser"
								@expired="onCaptchaExpired"
							/>
							<button type="button" class="btn-orange modal__btn" @click="nextStep('reg')">
								<span v-if="!formLoad">{{ $filters.localizeFilter('regmodal_submit') }}</span>
								<span v-else style="display:flex;align-items: center;">
									<img src="@/assets/unnamed.gif" alt="" width="20px" style="display: inline-block;margin-right:6px;">
									<span style="color: white;">Loading</span>
								</span>
							</button>
						</div>
					</div>

					<!-- step 3 -->
					<div v-else class="modal__step three">
						<h4 class="modal__title" v-text="this.$filters.localizeFilter('regmodal_result_ok')"></h4>
						<p class="modal__thy-text" v-html="this.$filters.localizeFilter('regmodal_result_ok1')"></p>
						<div class="modal__btn-cont">
							<button type="button" class="btn-orange modal__btn" @click="openEmailService" v-text="this.$filters.localizeFilter('regmodal_result_button')"></button>
						</div>
					</div>
				</form>
			</div>
		</b-modal>
	</div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs } from '@vuelidate/validators';
import Repository from '@/repositories/Repository';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const UserRepository = RepositoryFactory.get('user');
import { VueRecaptcha } from 'vue-recaptcha'

export default {
	name: 'ModalReg',
	setup() { return { v$: useVuelidate() }},
	data() {
		return {
			sitekey: '6LdCHt0ZAAAAAI_6dPSASmHbtUn4tDU2459v3iZJ',
			shiftY: 0.25,
			formSteps: 1,
			width1: 574,
			width: 754,
			'email': '',
			formLoad: false,
			password: '',
			repeatPassword: '',
			messanger: '',
			loginMessanger: null,
			experience: '',
			typeTraffic: [
				{
					name: this.$filters.localizeFilter('regmodal_traffic_1'),
					isChecked: false,
					key: 1
				},
				{
					name: this.$filters.localizeFilter('regmodal_traffic_2'),
					isChecked: false,
					key: 2
				},
				{
					name: this.$filters.localizeFilter('regmodal_traffic_3'),
					isChecked: false,
					key: 3
				},
				{
					name: this.$filters.localizeFilter('regmodal_traffic_4'),
					isChecked: false,
					key: 4
				},
				{
					name: this.$filters.localizeFilter('regmodal_traffic_5'),
					isChecked: false,
					key: 5
				},
				{
					name: this.$filters.localizeFilter('regmodal_traffic_6'),
					isChecked: false,
					key: 6
				},
				{
					name: this.$filters.localizeFilter('regmodal_traffic_7'),
					isChecked: false,
					key: 7
				},
				{
					name: this.$filters.localizeFilter('regmodal_traffic_8'),
					isChecked: false,
					other: '',
					key: 8
				}
			],
			from: [
				{
					name: this.$filters.localizeFilter('regmodal_from1_name'),
					isChecked: false,
					source: '',
					placeholder: this.$filters.localizeFilter('regmodal_from1_placeholder'),
					key: 1
				},
				{
					name: this.$filters.localizeFilter('regmodal_from2_name'),
					isChecked: false,
					source: '',
					placeholder: this.$filters.localizeFilter('regmodal_from2_placeholder'),
					key: 2
				},
				{
					name: this.$filters.localizeFilter('regmodal_from3_name'),
					isChecked: false,
					source: '',
					placeholder: this.$filters.localizeFilter('regmodal_from3_placeholder'),
					key: 3
				},
				{
					name: this.$filters.localizeFilter('regmodal_from4_name'),
					isChecked: false,
					source: '',
					placeholder: this.$filters.localizeFilter('regmodal_from4_placeholder'),
					key: 4
				},
				{
					name: this.$filters.localizeFilter('regmodal_from5_name'),
					isChecked: false,
					source: '',
					placeholder: this.$filters.localizeFilter('regmodal_from5_placeholder'),
					key: 5
				}
			],
			agree1: '',
			agree2: '',
			userData: {}
		};
	},
	components: { VueRecaptcha },
	validations: {
		'email': { email, required },
		password: {
			required,
			strongPassword(password) {
				return (
					/[a-z]/.test(password) && // checks for a-z
					/[A-Z]/.test(password) && // checks for A-Z
					/[0-9]/.test(password) && // checks for 0-9
					/\W|_/.test(password) && // checks for special char
					password.length >= 8
				);
			}
		},
		repeatPassword: { sameAsPassword: sameAs('password') },
		messanger: { required },
		loginMessanger: { required, isvalid(messanger) {
			return this.isvalid_fn();
		}},
		experience: { required },
		agree1: { required },
		agree2: { required },
		typeTrafficValid: {
			checked() {
				const isAnyCheched = this.typeTraffic.filter(v => v.isChecked);
				return !!isAnyCheched.length;
			},
			inputValid() {
				return this.typeTraffic[this.typeTraffic.length - 1].isChecked ? this.typeTraffic[this.typeTraffic.length - 1].other.length > 0 : true;
			}
		},
		from: {
			checked() {
				const isAnyCheched = this.from.filter(v => v.isChecked);
				return !!isAnyCheched.length;
			},
			inputValid() {
				const item = this.from.filter(v => v.isChecked);
				return item[0].source.length > 0;
				// return item.length ? item[0].source.length > 0 : true;
			}
		}
	},
	computed: {
		lang() {
			return this.$store.state.lang;
		}
	},
	watch: {
		agree1(val) {
			this.agree1 = val ? true : '';
		},
		agree2(val) {
			this.agree2 = val ? true : '';
		}
	},
	mounted() {
		// this.reCaptchaLoad()
	},
	methods: {
		async isvalid_fn(messanger) {
			return await Repository.get('/custom/register_valid_social', {
				'messanger': this.messanger,
				'messanger_login': this.loginMessanger
			}).then(function (response) {
				if (response.data == "1") {
				}
			});
		},
		openEmailService() {
			const to = `//${this.email.split('@')[1]}`;
			window.open(to, '_blank');
		},
		// async reCaptchaLoad() {
		//   const recaptcha = await load('6LdCHt0ZAAAAAI_6dPSASmHbtUn4tDU2459v3iZJ', {tabindex: 1000})
		//   console.log(recaptcha, 'recaptcha')
		//   const token = await recaptcha.execute()
		//   console.log(token, 'token')
		// },
		submitForm() {
			const typeTraffic = this.typeTraffic.reduce((acc, v) => {
				if (v.isChecked) {
					return v.other ? [...acc, [v.key, v.other]] : [...acc, v.key];
				}
				return acc;
			}, []);

			const from = this.from.reduce((acc, v) => {
				return v.isChecked ? [...acc, v.key, v.source] : acc;
			}, []);

			this.userData = {
				'email': this.email,
				password: this.password,
				'password-check': this.password,
				messanger: this.messanger,
				messanger_login: this.loginMessanger,
				experience: this.experience,
				from,
				typeTraffic,
				// agree: [this.agree1, this.agree2]
			};
			this.onCaptchaExpired()
			this.$refs.recaptcha.execute()
			// send the user to a server
		},
		onCaptchaExpired () {
			this.$refs.recaptcha.reset()
		},
		async regUser(recaptchaToken) {
			this.formLoad = true
			const t = this.accIsRequired
			const tOk= this.accReg
			this.userData['recaptchaToken'] = recaptchaToken
			await UserRepository.get(this.userData)
			.then(function (response) {
				if (response.data == "Saved") {
					tOk()
				} 
			})
			.catch(function (error) {
				if(error.response.status == 400) {
					t()
					if(error.response.data.error) {
						alert(error.response.data.error)
					} else {
						alert(error.response.data)
					}
				}
				console.log(error.response.status, error.response.data)
			})

			this.formLoad = false
		},
		accReg () {
			this.width = 574;
			this.formSteps = 3; // 2 steps
			this.shiftY = 0.35;
		},
		accIsRequired () {
			// this.password = ''
			// this.repeatPassword = ''
			this.formSteps = 1
		},
		setStep(step) {
			this.formSteps = step;
		},
		nextStep(reg) {
			// 123asdQWE!@#
			/* eslint-disable no-debugger */
			// debugger;
			if (reg === 'next') {
				// check step 1
				if (this.email && this.password && this.repeatPassword && this.messanger && this.loginMessanger && this.password == this.repeatPassword) {

					this.userData = {
						'email': this.email,
						password: this.password,
						'password-check': this.password,
						messanger: this.messanger,
						messanger_login: this.loginMessanger
					};
					
					this.onCaptchaExpired()

					this.$refs.recaptcha.execute()
		
					this.formLoad = true
				} else {
					// if something went wront - turn on validation
					this.v$.$touch();
					return;
				}
			} else if (reg === 'prev') {
				
			} else if (reg === 'reg') {
				// REG step
				if (this.v$.$invalid) {
					if (this.v$.typeTrafficValid) this.v$.$touch();
					return;
				} else {
					// generate data of user for server
					/* eslint-disable-next-line */
					this.submitForm();
				}
			}
		},
		async validStep1 (recaptchaToken) {
			this.userData['recaptchaToken'] = recaptchaToken
			let success_ = false
			
			await UserRepository.validate(this.userData)
				.then(function (response) {
					// go to 2 step
					success_ = true
					document.getElementById('modal__reg').classList.add('step2');
				})
				.catch(function (error) {
					alert(error.response.data)
					success_ = false

					if(!error.response.data) alert(this.$filters.localizeFilter('regmodal_error_unknown'))
				})
				
				this.formLoad = false

				if(success_) {
					this.width = 754;
					this.formSteps = 2;
				}
		},
		setCheckedObject(index) {
			this.from.forEach((item, idx) => {
				index === idx ? this.$set(this.from[index], 'isChecked', true) : this.$set(this.from[idx], 'isChecked', false);
			});
		}
	}
};
</script>

<style lang="scss">
#modal__reg {
	&.step1 {
		background: url(./../assets/img/modal__step1.svg) no-repeat center center/auto #e9eff7;
	}
	&.step2 {
		background: url(./../assets/img/modal__step2.svg) no-repeat center center/auto #e9eff7;
		.modal-dialog {
			max-width: 754px;
		}
	}
	&.finish {
		background: url(./../assets/img/modal__finish.svg) no-repeat center center/auto #e9eff7;
		.modal-dialog {
			margin-top: 10%;
		}
	}
	background: #e4eef6;
	.modal-dialog {
		margin-top: 5%;
		max-width: 574px;
		.modal-content {
			border: none !important;
			.modal-body {
				padding: 0px;
			}
		}
	}
}

.modal__content {
	background-color: white;
	box-shadow: -21px -10px 32px #f3f4f7, 23px 18px 60px #bccadc;
	border-radius: 9px;
	padding: 23px 25px 34px;
	form.modal__form {
		display: block;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.modal__step {
		div,
		p {
			&.invalid {
				color: $fail-validation !important;
			}
			&.validated {
				border-color: $right-validation;
			}
		}
		.modal__title {
			font: bold 1.55rem/1.2 $font;
			text-align: center;
			color: $grey2;
			margin-bottom: 20px;
		}
		.modal__input {
			display: block;
			width: 100%;
			padding: 8px 10px;
			background: $white2;
			border: none;
			box-sizing: border-box;
			border-radius: 9px;
			font: bold 0.87rem/1.3 $font;
			color: $grey;
			border-width: 2px;
			border-style: solid;
			border-color: $white2;
			margin-bottom: 10px;
			@include placeholder {
				font: 0.87rem/1.3 $font;
				color: $grey;
			}
			&:focus {
				outline: none;
			}
			&.invalid {
				border-color: $fail-validation;
				margin-bottom: 3px;
			}
			&.validated {
				border-color: $right-validation;
			}
		}
		.modal__btn {
			padding: 16px 62px;

			&.btn-white {
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				box-shadow: -7px -7px 17px rgba(243, 244, 247, 0.9), 7px 7px 17px rgba(188, 202, 220, 0.7);
				border-radius: 9px;
			}
		}
		// vlidation
		.validation-error {
			font: 600 0.87rem/1.2 $font;
			color: $fail-validation;
			padding-left: 10px;
			margin-bottom: 10px;
		}
		.modal__select-cont {
			position: relative;
			&:before {
				content: '';
				display: block;
				width: 9px;
				height: 5px;
				background: url(./../assets/arrow.svg) no-repeat center center/cover;
				pointer-events: none;
				position: absolute;
				right: 15px;
				top: calc(50% - 2px);
			}
		}
		input {
			appearance: none;
		}

		&.one {
			.modal__input-email {
				width: 100%;
			}
			.modal__pasword {
				display: flex;
				justify-content: space-between;
				.modal__password-cont {
					width: calc(50% - 5px);
				}
				.modal__input-password-check,
				.modal__input-password {
					width: 100%;
				}
			}
			.modal__text1 {
				font: 0.87rem/1.3 $font;
				color: $grey;
				margin-bottom: 22px;
			}

			.modal__select-messanger {
				appearance: none;
				&.invalid {
					margin-bottom: 10px;
				}
			}
			.modal__input-login-messanger {
				margin-bottom: 20px;
			}
			.modal__btn-cont {
				display: flex;
				justify-content: flex-end;
			}
		}
		&.two {
			.modal__select-exp {
				margin-bottom: 14px;
				appearance: none;
			}
			.modal__text2 {
				font: bold 0.87rem/1.3 $font;
				color: $grey;
				margin-bottom: 16px;
			}
			.modal__list {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				&:not(.modal__list-with-text-field) {
					.modal__text-field {
						margin-bottom: 13px;
					}
				}
				&.modal__list-with-text-field {
					margin-bottom: 15px;
					label {
						width: 100%;
						margin-right: 0px;
						// margin-bottom: 4px;
						display: flex;
						// align-items: center;
						flex-direction: column;
						input {
							&:checked + span {
								&:before {
									background: #eb6811;
								}
								&:after {
									background: white;
								}
							}
						}
						span {
							&:before {
								content: '';
								width: 16px;
								height: 16px;
								border-radius: 50%;
								background: #dde8f9;
								left: 0;
								top: calc(50% - 8px);
							}
							&:after {
								content: '';
								display: block;
								width: 8px;
								height: 8px;
								border-radius: 50%;
								background: transparent;
								position: absolute;
								left: 4px;
								top: calc(50% - 4px);
							}
						}
					}
					.modal__text-field {
						margin-top: 4px;
						// display: block;
						box-sizing: border-box;
						width: calc(100% - 18px);
						// padding: 4px 8px;
						// margin: 4px 0 0;
						margin-left: 18px;
					}
				}
				label {
					display: block;
					position: relative;
					margin-bottom: 13px;
					margin-right: 24px;
					cursor: pointer;
					&:last-child {
						margin-right: 0;
					}
					input:not(.modal__text-field) {
						display: block;
						width: 0;
						height: 0;
						position: absolute;
						left: 0;
						&:checked + span {
							&:before {
								background: #eb6811;
							}
							svg {
								display: block;
							}
						}
					}
					span {
						display: block;
						padding-left: 21px;
						position: relative;
						font: 0.87rem/1.3 $font;
						color: $grey;
						&:before {
							content: '';
							display: block;
							width: 15px;
							height: 15px;
							border-radius: 3px;
							background: #dde8f9;
							position: absolute;
							left: 0;
							top: calc(50% - 8px);
						}
						svg {
							display: none;
							position: absolute;
							left: 3px;
							top: calc(50% - 5px);
							path {
								// fill: red;
							}
						}
					}
				}
			}
			.modal__select-from {
				margin-bottom: 10px;
			}
			.modal__select-traffic-select,
			.modal__select-traffic {
				margin-bottom: 20px;
			}
			.modal__agree {
				display: block;
				width: 90%;
				position: relative;
				margin-bottom: 9px;
				cursor: pointer;
				&.invalid span {
					color: $fail-validation;
					&:before {
						border: 1px solid $fail-validation;
						// border: 1px solid blue;
					}
				}
				&:last-child {
					margin-right: 0;
				}
				input {
					display: block;
					width: 0;
					height: 0;
					position: absolute;
					left: 0;
					&:checked + span {
						&:before {
							background: #eb6811;
						}
						svg {
							display: block;
						}
					}
				}
				span {
					display: block;
					padding-left: 21px;
					position: relative;
					font: 0.87rem/1.3 $font;
					color: #98aed0;
					&:before {
						content: '';
						display: block;
						width: 15px;
						height: 15px;
						border-radius: 3px;
						background: #dde8f9;
						position: absolute;
						left: 0;
						top: 1px;
					}
					svg {
						display: none;
						position: absolute;
						left: 3px;
						top: 4px;
						path {
							// fill: red;
						}
					}
					a {
						color: #b4c5df;
						text-decoration-line: underline;
						&:hover {
							color: $orange;
						}
					}
				}
			}
			// btn
			.modal__btn-cont {
				display: flex;
				justify-content: flex-start;
			}
		}
		&.three {
			.modal__thy-text {
				display: block;
				width: 410px;
				max-width: 100%;
				margin: 0 auto;
				font: bold 1.18rem/1.15 $font;
				text-align: center;
				color: $grey;
				margin-bottom: 34px;
				span {
					text-decoration: underline;
				}
			}
			.modal__btn-cont {
				display: flex;
				justify-content: center;
				.modal__btn {
					padding: 16px 28px;
					font-size: 0.75rem;
					box-shadow: 0px 5px 5px rgba(228, 126, 59, 0.2);
				}
			}
		}
	}
}

@media (max-width: 992.98px) {
	#modal__reg {
		// background: red !important;
		&.finish {
			.modal-dialog {
				margin-top: 20%;
			}
		}
		.modal-dialog {
			margin-top: 70px;
		}
	}
	.modal__content {
		max-width: 100%;
		.modal__step {
			.modal__title {
				font: bold 1.55rem/1.2 $font;
				margin-bottom: 20px;
			}
			.modal__input {
				display: block;
				padding: 8px 10px;
				font: bold 0.87rem/1.3 $font;
				margin-bottom: 10px;
			}
			// vlidation
			.validation-error {
				font: 600 0.87rem/1.2 $font;
				padding-left: 10px;
				margin-bottom: 10px;
			}
			// modal btns
			.modal__btn-cont {
				display: flex;
				justify-content: space-between;
				// background: blue;
				.modal__btn {
					&.btn-orange {
						width: calc(100% - 60px);
					}
				}
			}

			&.one {
				.modal__input-email {
					width: 100%;
				}
				.modal__pasword {
					display: flex;
					justify-content: space-between;
					.modal__password-cont {
						width: calc(50% - 5px);
					}
					.modal__input-password-check,
					.modal__input-password {
						width: 100%;
					}
				}
				.modal__text1 {
					font: 0.87rem/1.3 $font;
					color: $grey;
					margin-bottom: 22px;
				}
				.modal__select-messanger {
					&.invalid {
						margin-bottom: 10px;
					}
				}
				.modal__input-login-messanger {
					margin-bottom: 20px;
				}
				.modal__btn-cont {
					justify-content: space-between;
				}
			}
			&.two {
				.modal__select-exp {
					margin-bottom: 14px;
				}
				.modal__text2 {
					font: bold 0.87rem/1.3 $font;
					margin-bottom: 16px;
				}
				.modal__list {
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					&:not(.modal__list-with-text-field) {
						.modal__text-field {
							margin-bottom: 13px;
						}
					}
					&.modal__list-with-text-field {
						margin-bottom: 15px;
						label {
							margin-right: 0px;
							flex-direction: column;
						}
						.modal__text-field {
							display: block;
							height: auto;
							margin-top: 4px;
							box-sizing: border-box;
							width: calc(100% - 0px);
							margin-left: 0px;
							position: relative;
							margin-bottom: 0px;
						}
					}
					label {
						margin-bottom: 13px;
						margin-right: 24px;
						span {
							padding-left: 21px;
							font: 0.87rem/1.3 $font;
							&:before {
								width: 15px;
								height: 15px;
								left: 0;
								top: calc(50% - 8px);
							}
						}
					}
				}
				.modal__select-from {
					margin-bottom: 10px;
				}
				.modal__select-traffic-select,
				.modal__select-traffic {
					margin-bottom: 20px;
				}
				.modal__agree {
					display: block;
					width: 90%;
					position: relative;
					margin-bottom: 9px;
					cursor: pointer;
					&.invalid span {
						color: $fail-validation;
						&:before {
							border: 1px solid $fail-validation;
							// border: 1px solid blue;
						}
					}
					&:last-child {
						margin-right: 0;
					}
					input {
						display: block;
						width: 0;
						height: 0;
						position: absolute;
						left: 0;
						&:checked + span {
							&:before {
								background: #eb6811;
							}
							svg {
								display: block;
							}
						}
					}
					span {
						display: block;
						padding-left: 21px;
						position: relative;
						font: 0.87rem/1.3 $font;
						color: #98aed0;
						&:before {
							content: '';
							display: block;
							width: 15px;
							height: 15px;
							border-radius: 3px;
							background: #dde8f9;
							position: absolute;
							left: 0;
							top: 1px;
						}
						svg {
							display: none;
							position: absolute;
							left: 3px;
							top: 4px;
							path {
								// fill: red;
							}
						}
						a {
							color: #b4c5df;
							text-decoration-line: underline;
							&:hover {
								color: $orange;
							}
						}
					}
				}
				// btn
				.modal__btn-cont {
					display: flex;
					justify-content: space-between;
				}
			}
			&.three {
				.modal__thy-text {
					display: block;
					width: 410px;
					max-width: 100%;
					margin: 0 auto;
					font: bold 1.18rem/1.15 $font;
					text-align: center;
					color: $grey;
					margin-bottom: 34px;
					span {
						text-decoration: underline;
					}
				}
				.modal__btn-cont {
					display: flex;
					justify-content: center;
					.modal__btn {
						padding: 16px 28px;
						font-size: 0.75rem;
						box-shadow: 0px 5px 5px rgba(228, 126, 59, 0.2);
					}
				}
			}
		}
	}
}
@media (max-width: 768.98px) {
	.modal__content {
		padding: 20px 5px 38px;
		.modal__step {
			.modal__title {
				font: bold 1rem/1.2 $font;
				margin-bottom: 18px;
			}
			.modal__input {
				padding: 8px 10px;
				font: bold 0.87rem/1.3 $font;
				border-width: 2px;
				margin-bottom: 10px;
			}
			// vlidation
			.validation-error {
				font: 600 0.87rem/1.2 $font;
				color: $fail-validation;
				padding-left: 10px;
				margin-bottom: 10px;
			}

			&.one {
				.modal__title {
					text-align: left;
				}
				.modal__input-email {
					width: 100%;
				}
				.modal__pasword {
					flex-direction: column;
					.modal__password-cont {
						width: 100%;
					}
				}
				.modal__text1 {
					font: 0.87rem/1.3 $font;
					margin-bottom: 22px;
				}
				.modal__select-messanger {
					&.invalid {
						margin-bottom: 10px;
					}
				}
				.modal__input-login-messanger {
					margin-bottom: 20px;
				}
			}
			&.two {
				.modal__select-exp {
					margin-bottom: 14px;
				}
				.modal__text2 {
					font: bold 0.87rem/1.3 $font;
					margin-bottom: 16px;
				}
				.modal__list {
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					label {
						display: block;
						position: relative;
						margin-bottom: 13px;
						margin-right: 24px;
						cursor: pointer;
						&:last-child {
							margin-right: 0;
						}
						input {
							display: block;
							width: 0;
							height: 0;
							position: absolute;
							left: 0;
							&:checked + span {
								&:before {
									background: #eb6811;
								}
								svg {
									display: block;
								}
							}
						}
						span {
							display: block;
							padding-left: 21px;
							position: relative;
							font: 0.87rem/1.3 $font;
							color: $grey;
							&:before {
								content: '';
								display: block;
								width: 15px;
								height: 15px;
								border-radius: 3px;
								background: #dde8f9;
								position: absolute;
								left: 0;
								top: calc(50% - 8px);
							}
							svg {
								display: none;
								position: absolute;
								left: 3px;
								top: calc(50% - 5px);
								path {
									// fill: red;
								}
							}
						}
					}
				}
				.modal__select-from {
					margin-bottom: 10px;
				}
				.modal__select-traffic-select,
				.modal__select-traffic {
					margin-bottom: 20px;
				}
				.modal__agree {
					display: block;
					width: 90%;
					position: relative;
					margin-bottom: 9px;
					cursor: pointer;
					&.invalid span {
						color: $fail-validation;
						&:before {
							border: 1px solid $fail-validation;
							// border: 1px solid blue;
						}
					}
					&:last-child {
						margin-right: 0;
					}
					input {
						display: block;
						width: 0;
						height: 0;
						position: absolute;
						left: 0;
						&:checked + span {
							&:before {
								background: #eb6811;
							}
							svg {
								display: block;
							}
						}
					}
					span {
						display: block;
						padding-left: 21px;
						position: relative;
						font: 0.87rem/1.3 $font;
						color: #98aed0;
						&:before {
							content: '';
							display: block;
							width: 15px;
							height: 15px;
							border-radius: 3px;
							background: #dde8f9;
							position: absolute;
							left: 0;
							top: 1px;
						}
						svg {
							display: none;
							position: absolute;
							left: 3px;
							top: 4px;
							path {
								// fill: red;
							}
						}
						a {
							color: #b4c5df;
							text-decoration-line: underline;
							&:hover {
								color: $orange;
							}
						}
					}
				}
			}
			&.three {
				.modal__thy-text {
					display: block;
					width: 410px;
					max-width: 100%;
					margin: 0 auto;
					font: 1rem/1.3 $font;
					text-align: center;
					color: $grey;
					margin-bottom: 34px;
					span {
						font-weight: bold;
						text-decoration: underline;
					}
				}
				.modal__btn-cont {
					display: flex;
					justify-content: center;
					.modal__btn {
						padding: 16px 28px;
						font-size: 0.75rem;
						box-shadow: 0px 5px 5px rgba(228, 126, 59, 0.2);
					}
				}
			}
		}
	}
	.modal__content .modal__step .modal__btn {
		padding: 16px 59px;
	}
	.grecaptcha-badge {
		bottom: 178px !important;
	}
}
@media (max-width: 354.98px) {
}
</style>
