<template>
  <div class="container">
    <h3 class="support__subtitle ml0" v-html="$filters.localizeFilter('support_subtitle4')"></h3>
    <ul class="support__list ml0">
      <li>
        <a href="//t.me/traforce/200" target="_blank">
          <div class="support__icon-cont">
            <svg width="32" height="21" viewBox="0 0 32 21" xmlns="http://www.w3.org/2000/svg" class="telegram">
              <path
                d="M12.5565 13.8404L12.0272 19.7036C12.7845 19.7036 13.1126 19.4474 13.5059 19.1397L17.0566 16.4675L24.4142 20.7105C25.7635 21.3027 26.7142 20.9909 27.0782 19.733L31.9077 1.91247L31.909 1.91142C32.337 0.340624 31.1877 -0.273623 29.873 0.111725L1.48561 8.67023C-0.451774 9.26243 -0.42244 10.1129 1.15626 10.4983L8.41377 12.2759L25.2715 3.96941C26.0649 3.55571 26.7862 3.78461 26.1929 4.1983L12.5565 13.8404Z"
                fill="#384456"
              />
            </svg>

            <p>{{ $filters.localizeFilter('support_manual') }}</p>
          </div>
          <p class="support__text1">{{ $filters.localizeFilter('support_postback') }}</p>
        </a>
      </li>
      <li>
        <a href="//t.me/traforce/281" target="_blank">
          <div class="support__icon-cont">
            <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" class="ring">
              <path
                d="M2.51972 13.6027C3.2098 13.6027 3.76964 13.0429 3.76964 12.3528C3.76964 8.84657 5.13469 5.55159 7.61348 3.07142C8.10215 2.58276 8.10215 1.79289 7.61348 1.304C7.12482 0.815334 6.33472 0.815334 5.84606 1.304C2.89463 4.2552 1.26979 8.17915 1.26979 12.3528C1.26979 13.0429 1.82964 13.6027 2.51972 13.6027Z"
                fill="#384456"
              />
              <path
                d="M27.5196 13.6027C28.2095 13.6027 28.7695 13.0429 28.7695 12.3528C28.7695 8.17915 27.1445 4.2552 24.1933 1.304C23.7046 0.815334 22.9145 0.815334 22.4258 1.304C21.937 1.79289 21.937 2.58276 22.4258 3.07142C24.9046 5.55021 26.2695 8.84657 26.2695 12.3528C26.2695 13.0429 26.8295 13.6027 27.5196 13.6027Z"
                fill="#384456"
              />
              <path
                d="M3.2956 21.1414C5.1857 19.5438 6.26946 17.2087 6.26946 14.735V11.25C6.26946 6.85135 9.53697 3.21007 13.7695 2.6001V1.24992C13.7695 0.558701 14.3295 0 15.0194 0C15.7095 0 16.2695 0.558701 16.2695 1.24992V2.6001C20.5032 3.21007 23.7695 6.85135 23.7695 11.25V14.735C23.7695 17.2087 24.8533 19.5438 26.7544 21.1512C27.2408 21.5675 27.5195 22.1725 27.5195 22.8124C27.5195 24.0189 26.5383 25.0001 25.3319 25.0001H4.70688C3.50067 25.0001 2.51946 24.0189 2.51946 22.8124C2.51946 22.1725 2.79823 21.5675 3.2956 21.1414Z"
                fill="#384456"
              />
              <path d="M15.0193 30C12.7557 30 10.8619 28.3862 10.4268 26.25H19.6118C19.1769 28.3862 17.2832 30 15.0193 30Z" fill="#384456" />
            </svg>

            <p>{{ $filters.localizeFilter('support_manual') }}</p>
          </div>
          <p class="support__text1">{{ $filters.localizeFilter('support_push') }}</p>
        </a>
      </li>
      <li>
        <a href="//t.me/traforce/114" target="_blank">
          <div class="support__icon-cont">
            <span class="ico">400</span>

            <p>{{ $filters.localizeFilter('support_case') }}</p>
          </div>
          <p class="support__text1">{{ $filters.localizeFilter('support_free_traffic') }}</p>
        </a>
      </li>
      <li>
        <a href="//t.me/traforce/128" target="_blank">
          <div class="support__icon-cont">
            <span class="ico">150</span>

            <p>{{ $filters.localizeFilter('support_case') }}</p>
          </div>
          <p class="support__text1">{{ $filters.localizeFilter('support_olx') }}</p>
        </a>
      </li>
    </ul>
    <button class="btn-orange support__btn" @click="goToEvents">{{ $filters.localizeFilter('reg') }}</button>
  </div>
</template>

<script>
export default {
  methods: {
    goToEvents: function () {
        if(this.$store.state.isDev) return this.launchModal('modal__reg')
        else location.href='//affiliate.traforce.com/v2/sign/up'
    }
  }
};
</script>

<style lang="scss" scoped>
.support__subtitle {
  margin-top: 40px;
  font: bold 1rem/1.3 $font;
  color: $grey2;
  margin-bottom: 23px;
  a {
    color: inherit;
    font-weight: 800;
  }
}

.ml0 {
  margin-left: -15px;
}

.support__btn {
  display: none;
}

.support__list {
  display: flex;
  justify-content: space-between;
  li {
    width: calc(25% - 5px);
    border-radius: 5px;
    display: flex;
    transition: all 0.25s;
    position: relative;
    z-index: 1;
    &:first-child {
      .support__icon-cont {
        min-height: 58px;
        svg {
          margin-bottom: 9px;
        }
      }
    }
    &:focus,
    &:hover {
      box-shadow: 13px 13px 20px #bccadc, -11px -10px 15px #f3f4f7;
      transition: all 0.25s;
      z-index: 2;
      svg.telegram path {
        fill: $tel;
        transition: all 0.25s;
      }
      svg.ring path {
        fill: #e6d900;
        transition: all 0.25s;
      }
      .ico {
        background: -webkit-linear-gradient(
          -45deg,
          rgba(243, 226, 199, 1) 0%,
          rgba(193, 158, 103, 1) 50%,
          rgba(182, 141, 76, 1) 51%,
          rgba(233, 212, 179, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $white1;
      border-radius: 7px;
      padding: 17px 15px 17px 15px;
      text-decoration: none;
      box-shadow: 0 0 0 transparent;
      transition: all 0.25s;
    }
    .support__icon-cont {
      min-height: 53px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      justify-content: space-around;
      justify-content: flex-end;
      align-items: center;
      svg {
        display: block;
        margin: 0 auto;
        margin-bottom: 5px;
        transition: all 0.25s;
      }
      .ico {
        font: 800 1.625rem/1.3 $font;
        color: $grey2;
        margin-bottom: 3px;
      }
      p {
        font: 600 0.75rem/1.3 $font;
        color: $grey3;
      }
    }
    .support__text1 {
      padding-left: 19px;
      padding-left: 15px;
      font: 600 0.87rem/1.3 $font;
      color: $grey;
    }
  }
}

@media (max-width: 1200.98px) {
  .support__list {
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: calc(50% - 5px);
      margin-bottom: 10px;
      &:first-child {
        .support__icon-cont {
          min-height: 58px;
          svg {
            margin-bottom: 9px;
          }
        }
      }
      a {
        width: 100%;
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: center;
        padding: 17px 15px 17px 15px;
      }
      .support__icon-cont {
        min-height: 53px;
        justify-content: flex-end;
        align-items: center;
        svg {
          margin: 0 auto;
          margin-bottom: 5px;
        }
        .ico {
          font: 800 1.625rem/1.3 $font;
          margin-bottom: 3px;
        }
        p {
          font: 600 0.75rem/1.3 $font;
        }
      }
      .support__text1 {
        padding-left: 15px;
        font: 600 0.87rem/1.3 $font;
      }
    }
  }
}
@media (max-width: 992.98px) {
  .ml0 {
    margin-left: auto;
  }
}
@media (max-width: 768.98px) {
  .support__subtitle {
    margin-top: 40px;
    font: bold 1rem/1.3 $font;
    text-align: center;
    margin-bottom: 23px;
    br {
      display: none;
    }
  }

  .support__list {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    li {
      width: 100%;
      margin-bottom: 10px;
      &:first-child {
        .support__icon-cont {
          min-height: 58px;
          svg {
            margin-bottom: 9px;
          }
        }
      }
      a {
        width: 100%;
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: center;
        padding: 17px 15px 17px 15px;
      }
      .support__icon-cont {
        min-height: 53px;
        justify-content: flex-end;
        align-items: center;
        svg {
          margin: 0 auto;
          margin-bottom: 5px;
        }
        .ico {
          font: 800 1.625rem/1.3 $font;
          margin-bottom: 3px;
        }
        p {
          font: 600 0.75rem/1.3 $font;
        }
      }
      .support__text1 {
        padding-left: 15px;
        font: 600 0.87rem/1.3 $font;
      }
    }
  }
  .support__btn {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 576.98px) {
}
@media (max-width: 320.98px) {
}
</style>
