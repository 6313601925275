import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import index from '@/pages/home';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{ path: '/:lang(en|ru|ua)?', component: index}
	],
})

router.beforeEach((to, from, next) => {
	const currL = store.getters.lang;
	const params = to.params.lang;
	const langs = ['en', 'ru', 'ua'];

	const device_lang = window.navigator.language.slice(0,2);
	const device_lang_ = ( device_lang.match(/en|de|by/) ? 'en' : 'ua' );

	if(!currL) {
		console.log('lang - none', currL)

		// if(params && params == 'en') {
		// 	store.commit('changeLang', 'en')
		// 	next({name: 'home', params:{lang: 'en'}})
		// 	return
		// }

		store.commit('changeLang', device_lang_)
	}

	langs.forEach( function(element) {
		if (params == element) {
			if (currL !== element) {
				store.commit('changeLang', element)
			}
		} else if (!params) {
			store.commit('changeLang', device_lang_)
		}
	});

	next()
})

export default router