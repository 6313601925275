<template>
  <section id="offers">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="title">
            {{ $filters.localizeFilter('offers_title') }}
            <img src="@/assets/q_fire.svg" />
            <div class="offers__hint d-none1 d-lg-block">
              <h5 class="offers__hint-title" v-html="$filters.localizeFilter('offers_hint_title')"></h5>
              <ul class="offers__hint-list">
                <li>- {{ $filters.localizeFilter('offers_hint_item1') }}</li>
                <li>- {{ $filters.localizeFilter('offers_hint_item2') }}</li>
                <li>- {{ $filters.localizeFilter('offers_hint_item3') }}</li>
                <li>- {{ $filters.localizeFilter('offers_hint_item4') }}</li>
                <li>- {{ $filters.localizeFilter('offers_hint_item5') }}</li>
                <li>- {{ $filters.localizeFilter('offers_hint_item6') }}</li>
              </ul>
            </div>
          </h2>
        </div>
      </div>
      <!-- tabs -->
      <div class="row">
        <div class="offers__slider">
          <!-- nav lg -->
          <ul v-if="width >= 992" class="offers__list">
            <li
              v-for="(slide, index) of slides"
              :key="slide.steam + index"
              :class="{ active: currentSlideComputed === index }"
              @click="setSlide(index)"
            >
              <div class="offers__brand-cont">
                <img
                  :src="slide.pagination.logoImg"
                  alt="brand logo"
                  class="offers__brand-logo"
                  :class="{ nataku: slide.offerTitle.indexOf('Nutaku') != -1 }"
                />
              </div>
              <p class="offers__area">
                {{ slide.pagination.text1 }}
                <span>{{ slide.pagination.text2 }}</span>
              </p>
            </li>

            <li class="offers__allofers" @click="goToEvents">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.63742 0.61832L4.64118 0.601149L4.64118 7.96185L2.32214 5.64791C2.20893 5.53461 2.05555 5.47245 1.89458 5.47245C1.7336 5.47245 1.5813 5.53461 1.46781 5.64791L1.10758 6.00796C0.994364 6.12118 0.931762 6.27205 0.931762 6.43294C0.931763 6.59392 0.993917 6.74487 1.10714 6.85809L5.07331 10.8246C5.18697 10.9383 5.33838 11.0004 5.49945 11C5.66141 11.0004 5.8129 10.9383 5.92639 10.8246L9.89292 6.85809C10.006 6.74487 10.0683 6.594 10.0683 6.43294C10.0683 6.27205 10.006 6.12118 9.89292 6.00796L9.53269 5.64791C9.41965 5.53461 9.26869 5.47245 9.10771 5.47245C8.94683 5.47245 8.80383 5.53461 8.6907 5.64791L6.3587 7.98796L6.3587 0.610092C6.3587 0.278572 6.07297 -4.9931e-06 5.74162 -4.97862e-06L5.23214 -4.95635e-06C4.9008 -4.94186e-06 4.63742 0.2868 4.63742 0.61832Z"
                  fill="#6C7D96"
                />
              </svg>
              {{ currentSlideComputed + 1 }} / {{ allOffers }}
            </li>
          </ul>
          <!-- nav sm -->
          <OffersSliderSM v-else :slides="slides" @ChangeContent="changeContent($event)" />

          <!-- Current slide/content -->
          <OffersSlide :slide="slides[currentSlideComputed]" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import OffersSlide from '@/components/OffersSlide';
import OffersSliderSM from '@/components/OffersSliderSM';

export default {
  name: 'OffersComponent',
  data() {
    return {
      slides: [
        {
          link: '//brides.datingonline.club/',
          steam: 'Dating Mainstream',
          money: 'up to $4',
          countries: ['WW'],
          reg: 'offers_reg',
          regType: '(SOI)',
          platforma: 'WEB+MOB',
          proposals: [
            {
              name: 'offers_creative_lp',
              isSupport: true
            },
            {
              name: 'offers_creative_creative',
              isSupport: true
            },
            {
              name: 'offers_creative_dl',
              isSupport: true
            }
          ],
          image: '/img/offer__slide5.png',
          offerTitle: 'VictoriyaClub.com - PPL',
          offerText: 'offers_brand_desc1',
          btnText: 'offer_btn',
          pagination: {
            logoImg: '/img/VictoriyaClub.png',
            text1: 'Dating Mainstream',
            text2: 'ppl - SOI'
          }
        },
        {
          link:
            '//23live.com/lp/page?n=9&wid=126591197595&promocode=6785e2t1ny9a08n603_%7Bpid%7D&linkid=702&gotopage=%2Flive-sex-chats%2Fcam-girls%2Fgallery%2F%3Flv%3D1&signup=2',
          steam: 'Webcam Adult',
          money: 'up to $4.8',
          countries: ['WW'],
          reg: 'offers_reg',
          regType: '(DOI)',
          platforma: 'WEB+MOB',
          proposals: [
            {
              name: 'offers_creative_lp',
              isSupport: true
            },
            {
              name: 'offers_creative_creative',
              isSupport: true
            },
            {
              name: 'offers_creative_dl',
              isSupport: true
            }
          ],
          image: '/img/offer__slide4.png',
          offerTitle: 'IMLIVE - PPL',
          offerText: 'offers_brand_desc2',
          btnText: 'offer_btn',
          pagination: {
            logoImg: '/img/IMLIVE.png',
            text1: 'Webcam Adult',
            text2: 'ppl - DOI'
          }
        },
        {
          link: '#',
          steam: 'Adult Games',
          money: 'up to $4.2',
          countries: ['no', 'at', 'ch', 'de', 'ca', 'fr', 'us', 'gb', 'nl', 'au', 'be', 'fi', 'dk', 'se', 'sg', 'jp'],
          reg: 'offers_reg',
          regType: '(DOI)',
          platforma: 'WEB+MOB',
          proposals: [
            {
              name: 'offers_creative_lp',
              isSupport: true
            },
            {
              name: 'offers_creative_creative',
              isSupport: true
            },
            {
              name: 'offers_creative_dl',
              isSupport: false
            }
          ],
          image: '/img/offer__slide3.png',
          offerTitle: 'Nutaku - ppl',
          offerText: 'offers_brand_desc3',
          btnText: 'offer_btn',
          pagination: {
            logoImg: '/img/nutaku-logo.png',
            text1: 'Adult Games',
            text2: 'ppl - DOI'
          }
        },
        {
          link: '//modullink.com/intimsearch4/',
          steam: 'Adult Dating',
          money: 'up to $0.6',
          countries: ['ru'],
          reg: 'offers_reg',
          regType: '(DOI)',
          platforma: 'WEB+MOB',
          proposals: [
            {
              name: 'offers_creative_lp',
              isSupport: true
            },
            {
              name: 'offers_creative_creative',
              isSupport: false
            },
            {
              name: 'offers_creative_dl',
              isSupport: true
            }
          ],
          image: '/img/offer__slide2.png',
          offerTitle: 'Sexy Lovers - ppl',
          offerText: 'offers_brand_desc4',
          btnText: 'offer_btn',
          pagination: {
            logoImg: '/img/sexy-lovers.png',
            text1: 'Adult Dating',
            text2: 'ppl - DOI'
          }
        },
        {
          link: '//navsegda.net/ac/c3f6ff22d3a2f464f7f1ee6a3c3a3c9319d69d182',
          steam: 'Dating Mainstream',
          money: 'up to $1.6',
          countries: ['ua', 'ge', 'by', 'ru', 'lv', 'lt', 'ee', 'az', 'am', 'kz', 'md'],
          reg: 'offers_reg',
          regType: '(SOI)',
          platforma: 'WEB+MOB',
          proposals: [
            {
              name: 'offers_creative_lp',
              isSupport: true
            },
            {
              name: 'offers_creative_creative',
              isSupport: false
            },
            {
              name: 'offers_creative_dl',
              isSupport: false
            }
          ],
          image: '/img/offer__slide1.png',
          offerTitle: 'Navsegda - ppl',
          offerText: 'offers_brand_desc5',
          btnText: 'offer_btn',
          pagination: {
            logoImg: '/img/navsegda.png',
            text1: 'Dating Mainstream',
            text2: 'ppl - SOI'
          }
        }
      ],
      currentSlide: 0,
      allOffers: 175,
      width: window.innerWidth
    };
  },
  components: {
    OffersSlide,
    OffersSliderSM
  },
  methods: {
    changeContent(slideTo) {
      this.currentSlide = slideTo;
    },
    setSlide(index) {
      this.currentSlide = index;
    },
    incrementOffers() {
      setTimeout(() => {
        this.allOffers += 3;
        this.incrementOffers();
      }, 3000);
    },
    goToEvents: function () {
        if(this.$store.state.isDev) return this.launchModal('modal__reg')
        else location.href='//affiliate.traforce.com/v2/sign/up'
    }
  },
  computed: {
    currentSlideComputed() {
      return this.currentSlide;
    }
  },
  mounted() {
    this.incrementOffers();
  }
};
</script>

<style lang="scss">
#offers {
  padding: 0 0 50px 0;
  padding: 0 0 70px 0;
  .title {
    margin-bottom: 35px;
    position: relative;
    img {
      display: inline-block;
      margin-left: 10px;
      position: relative;
      bottom: -15px;
      z-index: 35;
      &:focus,
      &:hover {
        cursor: pointer;
        & + .offers__hint {
          opacity: 1;
          transition: all 0.25s;
        }
      }
    }
    .offers__hint {
      max-width: 100%;
      height: auto;
      padding: 20px 26px 31px;
      background: $bg;
      background: white;
      box-shadow: -21px -10px 32px #f3f4f7, 23px 18px 60px #bccadc;
      border-radius: 9px;
      position: absolute;
      top: 0px;
      left: 185px;
      opacity: 0;
      z-index: 33;
      transition: all 0.25s;
      .offers__hint-title {
        font: bold 1.5rem/1.2 $font;
        color: $grey2;
        padding-left: 30px;
        margin-bottom: 20px;
      }
      .offers__hint-svg {
        display: block;
        max-width: 100%;
        width: 100%;
      }
      .offers__hint-list {
        li {
          font: 600 0.87rem/1.3 $font;
          color: $grey;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .offers__slider {
    display: flex;
    justify-content: space-between;
    // pagination
    .offers__list {
      width: 300px;
      padding-right: 10px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        max-height: 70px;
        background: white;
        filter: grayscale(100%);
        border-radius: 9px;
        opacity: 0.2;
        padding: 20px 15px;
        margin-bottom: 25px;
        overflow: hidden;
        transition: all 0.25s;
        z-index: 2;
        .offers__brand-logo {
          display: block;
          max-width: 70%;
          max-height: 50px;
          &.nataku {
            max-width: 70%;
            // max-height: 33px;
          }
        }
        .offers__area {
          font: bold 0.7rem/1.3 $font;
          text-align: center;
          color: $grey2;
          span {
            display: block;
            text-transform: uppercase;
            font-size: 1.125rem;
            text-align: center;
          }
        }
        &.active {
          padding: 20px 15px;
          background: $accent;
          filter: grayscale(0%);
          max-height: 70px;
          min-height: 65px;
          filter: drop-shadow(15px 11px 19px rgba(119, 155, 185, 0.52));
          position: relative;
          z-index: 31;
          opacity: 1;
          .offers__brand-cont {
            width: 100%;
            max-height: 30px;
            min-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .offers__brand-logo {
            display: block;
            width: auto;
            max-height: 30px;
            margin: 0 auto;
            &.nataku {
              max-width: 50%;
              max-height: 53px;
              max-height: auto;
              image-rendering: crisp-edges;
            }
          }
          .offers__area {
            display: none;
          }
        }
        &:nth-child(1) {
          background: white;
          &.active {
            background: white;
          }
        }
        &:nth-child(2) {
          background: #59bf40;
          .offers__area {
            color: white;
          }
          &.active {
            background: #59bf40;
            img {
              filter: invert(100%);
              filter: invert(0%);
            }
          }
        }
        &:nth-child(3) {
          background: #9a2e54;
          .offers__area {
            color: white;
          }
          &.active {
            background: #9a2e54;
          }
        }
        &:nth-child(4) {
          background: white;
          &.active {
            background: white;
          }
        }
        &:nth-child(5) {
          background: #294762;
          margin-bottom: 6px;
          .offers__area {
            color: white;
          }
          &.active {
            background: #294762;
          }
        }
        &:hover {
          opacity: 1;
          cursor: pointer;
          transition: all 0.25s;
          filter: drop-shadow(15px 11px 19px rgba(119, 155, 185, 0.52));
          z-index: 4;
          transform: scale(1.1);
        }

        &:last-child {
          display: block;
          background: linear-gradient(103.73deg, #e9eff7 13.49%, #e2e8f0 97.05%);
          box-shadow: 7px 7px 17px rgba(188, 202, 220, 0.7), -7px -7px 17px rgba(243, 244, 247, 0.9);
          border-radius: 9px;
          padding: 14px 0;
          opacity: 1;
          filter: grayscale(0%);
          font: bold 0.95rem/1.3 $font;
          text-align: center;
          color: $grey2;
          &.active,
          &:hover {
            opacity: 1;
            transform: scale(1);
            position: relative;
            z-index: 33;
            filter: none;
          }
        }
      }
    }
    .offers__swiper {
      width: calc(100% - 200px);
    }
    // content <component>
  }
}

@media (max-width: 1200.98px) {
}
@media (max-width: 992.98px) {
  #offers {
    padding: 0 0 34px 0;
    padding: 0 0 44px 0;
    .title {
      font-size: 1.125rem;
      text-align: center;
      margin-bottom: 0px;
      img {
        // display: none;
        margin-left: 10px;
        bottom: -15px;
        &:hover + .offers__hint,
        &:focus + .offers__hint {
          z-index: 33;
        }
      }
      .offers__hint {
        max-width: 100%;
        height: auto;
        padding: 20px 15px;
        background: white;
        box-shadow: -21px -10px 32px #f3f4f7, 23px 18px 60px #bccadc;
        border-radius: 9px;
        position: absolute;
        top: 0px;
        left: 0;
        opacity: 0;
        z-index: 1;
        transition: all 0.25s;
        .offers__hint-title {
          font: bold 1.125rem/1.2 $font;
          text-align: left;
          padding-left: 0px;
          margin-bottom: 20px;
          span {
            display: block;
          }
        }
        .offers__hint-svg {
          display: block;
          max-width: 100%;
          width: 100%;
        }
        .offers__hint-list li {
          font: 600 0.87rem/1.3 $font;
          text-align: left;
        }
      }
    }

    .offers__slider {
      max-width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      // pagination
      .offers__list {
        width: 300px;
        padding-right: 10px;
        li {
          max-height: 70px;
          padding: 20px 15px;
          margin-bottom: 25px;
          .offers__brand-logo {
            max-width: 50%;
          }
          .offers__area {
            font: bold 0.7rem/1.3 $font;
            span {
              font-size: 1.125rem;
            }
          }
          &:nth-child(5) {
            margin-bottom: 6px;
          }

          &.active {
            padding: 20px 15px;
            max-height: 70px;
            min-height: 65px;
            .offers__brand-logo {
              width: auto;
              height: 30px;
            }
          }
          &:hover {
            transform: scale(1.1);
          }

          &:last-child {
            font: bold 0.95rem/1.3 $font;
          }
        }
      }
      .offers__swiper {
        width: 100%;
      }
      // content <component>
    }
  }
}
@media (max-width: 768.98px) {
}
@media (max-width: 576.98px) {
}
@media (max-width: 320.98px) {
}
</style>
