// define global function for launch a modals
export default {
  methods: {
    launchModal(str) {
      if(window.innerWidth < 992) this.$store.commit('changeOpenedMenu', false);
      this.$root.$emit('bv::show::modal', str)
    },
    closeModal(str) {
      this.$root.$emit('bv::hide::modal', str)
    },
    closeAllModals() {
      this.$store.state.isModalOpen = false;
      this.$store.state.isModalInfoOpen = false;
    }
  }
}

