<template>
  <section id="eggs">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 d-none d-lg-block">
          <img src="@/assets/eggs__img.svg" alt="CTR, CR, Profit" class="eggs__img" />
          <button class="btn-orange eggs__btn" @click="goToEvents">{{ $filters.localizeFilter('reg') }}</button>
        </div>
        <div class="col-md-12 col-lg-8 ">
          <div class="eggs__textcont">
            <img src="@/assets/q_fire.svg" alt="hint" class="eggs__qfire" />
            <div class="eggs__hint">
              <h5 class="eggs__hint-title">{{ $filters.localizeFilter('eggs_title') }}</h5>
              <p class="eggs__hint-text1">
                {{ $filters.localizeFilter('eggs_text1') }}
              </p>
            </div>
            <h2 class="title">
              {{ $filters.localizeFilter('eggs_text2') }}
            </h2>
            <img src="@/assets/eggs__img.svg" alt="" class="eggs__img d-block d-lg-none" />
            <ul class="eggs__list">
              <li data-aos="fade-up" data-aos-delay="50">{{ $filters.localizeFilter('eggs_list1') }}</li>
              <li data-aos="fade-up" data-aos-delay="50">{{ $filters.localizeFilter('eggs_list2') }}</li>
              <li data-aos="fade-up" data-aos-delay="50">{{ $filters.localizeFilter('eggs_list3') }}</li>
              <li data-aos="fade-up" data-aos-delay="50">{{ $filters.localizeFilter('eggs_list4') }}</li>
              <li data-aos="fade-up" data-aos-delay="50">{{ $filters.localizeFilter('eggs_list5') }}</li>
              <li data-aos="fade-up" data-aos-delay="50">{{ $filters.localizeFilter('eggs_list6') }}</li>
              <li data-aos="fade-up" data-aos-delay="50">{{ $filters.localizeFilter('eggs_list7') }}</li>
              <li data-aos="fade-up" data-aos-delay="50">{{ $filters.localizeFilter('eggs_list8') }}</li>
            </ul>
            <button class="btn-orange eggs__btn d-none d-block d-lg-none" @click="goToEvents">{{ $filters.localizeFilter('reg') }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    goToEvents: function () {
        if(this.$store.state.isDev) return this.launchModal('modal__reg')
        else location.href='//affiliate.traforce.com/v2/sign/up'
    }
  }
};
</script>

<style lang="scss">
#eggs {
  width: 100%;
  position: relative;
  padding-bottom: 70px;
  padding-bottom: 86px;
  &::before {
    content: url(../assets/eggs__bg.svg);
    position: absolute;
    left: calc(50% - 585px);
    left: calc(50% - 570px);
    top: 20px;
  }
  .eggs__img {
    display: block;
    max-width: 100%;
    margin-bottom: 12px;
  }
  .eggs__btn {
    margin: 0 auto;
  }
  .eggs__textcont {
    padding-left: 68px;
    box-sizing: border-box;
    position: relative;
  }

  .eggs__qfire {
    margin-top: 70px;
    margin-left: -40px;
    position: relative;
    z-index: 4;
    &:focus,
    &:hover {
      cursor: pointer;
      & + .eggs__hint {
        opacity: 1;
        transition: all 0.25s;
      }
    }
  }
  .eggs__hint {
    max-width: 100%;
    height: auto;
    padding: 20px 26px 31px;
    background: white;
    box-shadow: -21px -10px 32px #f3f4f7, 23px 18px 60px #bccadc;
    border-radius: 9px;
    position: absolute;
    top: 60px;
    left: 15px;
    opacity: 1;
    opacity: 0;
    z-index: 3;
    transition: all 0.25s;
    .eggs__hint-title {
      font: bold 1.5rem/1.2 $font;
      color: $grey2;
      padding-left: 30px;
      margin-bottom: 20px;
    }
    .eggs__hint-text1 {
      font: 600 0.87rem/1.3 $font;
      color: $grey;
    }
  }
  .title {
    margin-bottom: 53px;
  }
  .eggs__list {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: calc(50% - 30px);
      margin-bottom: 27px;
      padding-left: 27px;
      font: 600 0.87rem/1.3 $font;
      color: $grey;
      position: relative;
      &::before {
        content: url(../assets/pentagon.svg);
        position: absolute;
        left: 0;
        top: 0px;
      }
    }
  }
}
@media (max-width: 1200.98px) {
}
@media (max-width: 992.98px) {
  #eggs {
    padding-bottom: 55px;
    &::before {
      display: none;
    }
    .eggs__img {
      margin: 0 auto;
      width: 70%;
      margin-top: 12px;
      margin-bottom: 15px;
    }
    .eggs__btn {
      margin: 0 auto;
    }
    .eggs__textcont {
      padding-left: 0px;
    }

    .eggs__qfire {
      margin-top: 10px;
      margin-left: 0px;
    }
    .eggs__hint {
      padding: 20px 16px;
      box-shadow: -21px -10px 32px #f3f4f7, 23px 18px 60px #bccadc;
      position: absolute;
      top: 0px;
      left: -10px;
      opacity: 0;
      .eggs__hint-title {
        font: bold 1.125rem/1.2 $font;
        padding-left: 30px;
        margin-bottom: 15px;
      }
      .eggs__hint-text1 {
        font: 600 0.87rem/1.3 $font;
      }
    }
    .title {
      text-align: center;
      margin-bottom: 0px;
    }
    .eggs__list {
      width: 100%;
      padding: 18px 8px;
      margin-bottom: 16px;
      background: transparent;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url(../assets/eggs__bg.svg) no-repeat right top/cover;
        border-radius: 30px;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      li {
        width: 100%;
        margin-bottom: 12px;
        padding-left: 17px;
        font: 600 0.87rem/1.3 $font;
        margin-left: 8px;
        &::before {
          content: url(../assets/pentagon.svg);
          left: 0;
          top: -1px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media (max-width: 768.98px) {
  #eggs {
    .eggs__list {
      padding: 18px 0;
      margin-bottom: 16px;
      padding-right: 30px;
    }
  }
}
@media (max-width: 576.98px) {
  #eggs {
    padding-bottom: 45px;
    .eggs__img {
      width: 70%;
    }
    .eggs__btn {
      margin: 0 auto;
    }
    .eggs__textcont {
      padding-left: 15px;
      padding-left: 0;
      box-sizing: border-box;
    }

    .eggs__qfire {
      margin-top: 0px;
      margin-bottom: -70px;
      margin-left: 0px;
      position: relative;
      // z-index: 1;
    }
    .eggs__hint {
      padding: 20px 16px;
      top: 30px;
      left: 0px;
      .eggs__hint-title {
        font: bold 1.125rem/1.2 $font;
        padding-left: 20px;
        margin-bottom: 15px;
      }
    }
    .title {
      text-align: center;
      margin-bottom: 0px;
      padding: 0 25px;
    }
    .eggs__list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 18px 0;
      margin-bottom: 16px;
      padding-right: 40px;
      &:before {
        content: '';
        display: block;
        width: auto;
        height: auto;
        background: url(../assets/eggs__bg-sm.svg) no-repeat right top/cover;
        border-radius: 30px;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -15px;
        right: -15px;
      }
      li {
        width: 100%;
        margin-bottom: 12px;
        padding-left: 17px;
        font: 600 0.87rem/1.3 $font;
        margin-left: 0px;
        &::before {
          content: url(../assets/pentagon.svg);
          left: 0;
          top: 0px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media (max-width: 320.98px) {
  #eggs {
    .title {
      padding: 0 15px;
    }
  }
}
</style>
