import axios from 'axios'

// const baseDomain = 'http://localhost:3000'
const baseDomain = ''

const baseURL = '//traforce.com'
// const baseURL = '//' + window.location.hostname

export default axios.create({
    baseURL
})
