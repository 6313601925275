<template>
  <div
    class="header"
    :class="{ 'mobile-menu-opened': isMenuOpened, 'go-down': isGoDown, 'modal-opened': isModalOpen, 'modalinfo-opened': isModalInfoOpen }"
  >
    <!-- >992 -->
    <div v-if="width >= 992" class="header__container">
      <div class="left">
        <a href="#app" class="logo" @click="scrollToElement(0);closeAllModals()">
          <img src="@/assets/logo.svg" alt="logotype" />
        </a>

        <nav>
          <ul class="list">
            <li>
              <a href="#" @click.prevent="this.$store.state.isModalInfoOpen = true;">{{ $filters.localizeFilter('about') }}</a>
            </li>
            <li>
              <a href="#offers" @click.prevent="scrollToElement">{{ $filters.localizeFilter('offers') }}</a>
            </li>
            <li>
              <a href="#support" @click.prevent="scrollToElement">{{ $filters.localizeFilter('learn_support') }}</a>
            </li>
            <li>
              <a href="#sliderRev" @click.prevent="scrollToElement">{{ $filters.localizeFilter('reviews') }}</a>
            </li>
          </ul>
        </nav>
      </div>

      <div class="right">
        <div class="header__sign">
          <p class="text">{{ $filters.localizeFilter('signin') }}</p>
          <a href="//affiliate.traforce.com" target="_blank" class="login btn-white">
            <img src="@/assets/login-icon.svg" alt="icon" />
          </a>
          <a @click.prevent="toggleClass();goToEvents()" class="reg btn-orange">{{ $filters.localizeFilter('reg') }}</a>

          <div class="header__langcont">
            <select name="lang" v-model="lang">
              <option :value="i" :selected="this.$store.state.lang == i" v-for="i in $store.state.languages" :key="i">{{ i.toUpperCase() }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- <992 -->
    <div v-else class="header__container">
      <!-- top menu -->
      <div class="header__mobile">
        <img src="@/assets/logo.svg" alt="logotype" class="logo-sm" href="#app" @click.prevent="scrollToElement" />
        <div class="header__langcont">
          <select name="lang" v-model="lang">
            <option :value="i" :selected="this.$store.state.lang == i" v-for="i in $store.state.languages" :key="i">{{ i.toUpperCase() }}</option>
          </select>
        </div>

        <!-- modal -->
        <button class="login btn-white close-all-modals" @click="closeAllModals">
          <!-- close -->
          <svg class="login__close" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.0498 1.3328L1.22191 15.1607C0.744603 15.638 0.744603 16.4118 1.22191 16.8892C1.69922 17.3665 2.4731 17.3665 2.95041 16.8892L16.7783 3.0613C17.2556 2.58399 17.2556 1.81011 16.7783 1.3328C16.301 0.85549 15.5271 0.85549 15.0498 1.3328Z"
              fill="#6C7D96"
            />
            <path
              d="M16.7785 14.9386L2.95067 1.11071C2.47336 0.633396 1.69949 0.633396 1.22218 1.11071C0.744869 1.58802 0.744869 2.36189 1.22218 2.8392L15.05 16.6671C15.5273 17.1444 16.3012 17.1444 16.7785 16.6671C17.2558 16.1897 17.2558 15.4159 16.7785 14.9386Z"
              fill="#6C7D96"
            />
          </svg>
        </button>

        <!-- menu -->
        <button class="login btn-white" :class="{ active: isMenuOpened }" @click="toggleClass">
          <!-- open -->
          <svg class="login__burger" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.7778 15.8888H1.22223C0.547212 15.8888 0 16.436 0 17.111C0 17.786 0.547211 18.3333 1.22223 18.3333H20.7778C21.4528 18.3333 22 17.786 22 17.111C22 16.436 21.4528 15.8888 20.7778 15.8888Z"
              fill="#6C7D96"
            />
            <path
              d="M20.7778 9.77783H1.22223C0.547212 9.77783 0 10.325 0 11.0001C0 11.6751 0.547211 12.2223 1.22223 12.2223H20.7778C21.4528 12.2223 22 11.6751 22 11.0001C22 10.325 21.4528 9.77783 20.7778 9.77783Z"
              fill="#6C7D96"
            />
            <path
              d="M20.7778 3.66663H1.22223C0.547211 3.66663 0 4.21384 0 4.88886C0 5.56387 0.547211 6.11108 1.22223 6.11108H20.7778C21.4528 6.11108 22 5.56387 22 4.88886C22 4.21384 21.4528 3.66663 20.7778 3.66663Z"
              fill="#6C7D96"
            />
          </svg>
          <!-- close -->
          <svg class="login__close" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.0498 1.3328L1.22191 15.1607C0.744603 15.638 0.744603 16.4118 1.22191 16.8892C1.69922 17.3665 2.4731 17.3665 2.95041 16.8892L16.7783 3.0613C17.2556 2.58399 17.2556 1.81011 16.7783 1.3328C16.301 0.85549 15.5271 0.85549 15.0498 1.3328Z"
              fill="#6C7D96"
            />
            <path
              d="M16.7785 14.9386L2.95067 1.11071C2.47336 0.633396 1.69949 0.633396 1.22218 1.11071C0.744869 1.58802 0.744869 2.36189 1.22218 2.8392L15.05 16.6671C15.5273 17.1444 16.3012 17.1444 16.7785 16.6671C17.2558 16.1897 17.2558 15.4159 16.7785 14.9386Z"
              fill="#6C7D96"
            />
          </svg>
        </button>
      </div>

      <!-- menu content -->
      <div class="header__menu">
        <!-- sales block -->
        <Sales :data="saleData" v-if="saleData"  />

        <!-- nav -->
        <ul class="list2">
          <li>
            <a href="#" @click.prevent="this.$store.state.isModalInfoOpen = true;">{{ $filters.localizeFilter('about') }}</a>
          </li>
          <li>
            <a href="#offers" @click.prevent="toggleClass(); scrollToElement($event); ">{{ $filters.localizeFilter('offers') }}</a>
          </li>
          <li>
            <a href="#support" @click.prevent="toggleClass(); scrollToElement($event); ">{{ $filters.localizeFilter('learn_support') }}</a>
          </li>
          <li>
            <a href="#sliderRev" @click.prevent="toggleClass(); scrollToElement($event); ">{{ $filters.localizeFilter('reviews') }}</a>
          </li>
        </ul>

        <!-- social -->
        <ul class="header__social">
          <li>
            <a href="//t.me/traforce" class="telegram" target="_blank">
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                    <path
                      d="M9.417 15.181L9.02 20.765C9.588 20.765 9.834 20.521 10.129 20.228L12.792 17.683L18.31 21.724C19.322 22.288 20.035 21.991 20.308 20.793L23.93 3.82104L23.931 3.82004C24.252 2.32404 23.39 1.73904 22.404 2.10604L1.11399 10.257C-0.339005 10.821 -0.317005 11.631 0.866995 11.998L6.31 13.691L18.953 5.78004C19.548 5.38604 20.089 5.60404 19.644 5.99804L9.417 15.181Z"
                      fill="#6C7D96"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              @traforce
            </a>
          </li>
          <li>
            <a href="//www.youtube.com/channel/UCv6MaaPIPZg8qRGYYU4DKzw" class="youtube" target="_blank">
              <span>
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M25.6215 7.17327C25.3298 6.08887 24.4748 5.23405 23.3906 4.94205C21.4098 4.40009 13.4865 4.40009 13.4865 4.40009C13.4865 4.40009 5.56353 4.40009 3.58274 4.92151C2.51935 5.21319 1.64352 6.08903 1.35183 7.17327C0.830566 9.1539 0.830566 13.2615 0.830566 13.2615C0.830566 13.2615 0.830566 17.3897 1.35183 19.3497C1.64383 20.4339 2.4985 21.2887 3.5829 21.5807C5.58438 22.1229 13.4868 22.1229 13.4868 22.1229C13.4868 22.1229 21.4098 22.1229 23.3906 21.6014C24.475 21.3096 25.3298 20.4548 25.6218 19.3705C26.1429 17.3897 26.1429 13.2823 26.1429 13.2823C26.1429 13.2823 26.1638 9.1539 25.6215 7.17327ZM10.9639 17.0562V9.46676L17.5525 13.2615L10.9639 17.0562Z"
                    fill="#6C7D96"
                  />
                </svg>
              </span>
              youtube_traforce
            </a>
          </li>
        </ul>

        <!-- btns -->
        <div class="header__btns">
          <a href="//affiliate.traforce.com" target="_blank" class="btn-white  mainslier__login">
            <span>{{ $filters.localizeFilter('signin') }}</span>
            <img src="@/assets/login-icon.svg" alt="icon" />
          </a>
          <button class="btn-orange mainslier__reg" @click.prevent="toggleClass(); goToEvents();">
            {{ $filters.localizeFilter('reg') }}
          </button>
        </div>
        <!-- end -->
      </div>
    </div>
  </div>
</template>

<script>
import Sales from '@/components/Sales';

export default {
  name: 'my-header',
  data() {
    return {
      width: window.innerWidth,
      lang: this.$store.state.lang,
      lang2: ['ua', 'ru', 'us', 'de', 'es'],
      isGoDown: false,
      saleData: null
    };
  },
  computed: {
    // saleData() {
    // it doesn't work
    //   return this.$store.state.saleData ? this.$store.state.sales.saleData : this.$store.state.sales.defaultTemplate;
    // },
    isModalOpen() {
      return this.$store.state.isModalOpen;
    },
    isModalInfoOpen() {
      return this.$store.state.isModalInfoOpen;
    },
    isMenuOpened() {
      return this.$store.state.isMenuOpened;
    }
  },
  methods: {
    toggleClass() {
      this.$store.commit('changeOpenedMenu');
      // this.isMenuOpened = !this.isMenuOpened;
    },
    goToEvents: function () {
        if(this.$store.state.isDev)
          // return document.getElementById('modal__reg').$emit('show')
          // this.$refs['modal__reg'].$emit('show.bs.modal');
          this.$store.state.isModalOpen = true;
        else location.href='//affiliate.traforce.com/v2/sign/up'
    }
  },
  watch: {
    lang(value) {
      // this.$store.commit('changeLang', value);
      if (value == 'en') this.$router.push({path: "/en"})
      else if (value == 'ru') this.$router.push({path: "/ru"})
      else if (value == 'ua') this.$router.push({path: "/"})
    },
    isMenuOpened(val) {
      const body = document.querySelector('body');
      val ? body.classList.add('overflow-hidden') : body.classList.remove('overflow-hidden');
    }
  },
  filters: {
    toUpperCase: function(value) {
      return value.toUpperCase();
    }
  },
  components: {
    Sales
  },
  mounted() {
    document.addEventListener('scroll', () => {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.isGoDown = scrollTop > 50 ? true : false;
    });
    this.$store.dispatch('devStatus');
  },
  async created() {
    // dublicate, DRY is not support, no time to fix...
    const res = await this.$store.dispatch('fetchSalesData');
    if (res && res.length && res.status === 200) {
      this.saleData = res.data;
      if (!res.data || !res.data.length) this.saleData = this.$store.state.sales.defaultTemplate;
    } else {
      this.saleData = this.$store.state.sales.defaultTemplate;
    }
  }
};
</script>

<style lang="scss">
.header {
  width: 100%;
  padding: 36px 0 10px 0;
  background: $bg;
  position: fixed;
  top: 0;
  z-index: 1100;
  transition: all 0.25s;
  &.go-down {
    transition: all 0.25s;
    padding: 19px 0;
    box-shadow: -21px -10px 32px #f3f4f7, 23px 18px 60px #bccadc;
  }
  &.modalinfo-opened {
    // background: transparent;
    &.go-down {
      box-shadow: 0 0 0 #f3f4f7;
    }
    .header__container {
      .header__langcont select,
      .header__sign .text {
        color: $grey2;
      }
      .header__sign .login {
        box-shadow: -7px -7px 17px rgba(243, 244, 247, 0.9), 7px 7px 17px rgba(188, 202, 220, 0.7);
      }
      .list {
        // display: none;
        // width: 0px;
        // padding: 0;
        // margin: 0;
        overflow: hidden;
        transition: all 0.25s;
      }
    }
  }
  &.modal-opened {
    background: transparent;
    &.go-down {
      box-shadow: 0 0 0 #f3f4f7;
    }
    .header__container {
      .header__langcont select,
      .header__sign .text {
        color: $grey2;
      }
      .header__sign .login {
        box-shadow: -7px -7px 17px rgba(243, 244, 247, 0.9), 7px 7px 17px rgba(188, 202, 220, 0.7);
      }
      .header__sign .reg,
      .list {
        // display: none;
        width: 0px;
        padding: 0;
        margin: 0;
        overflow: hidden;
        transition: all 0.25s;
      }
    }
  }
}

.header__container {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  .left {
    min-width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .logo {
    display: block;
    margin-right: 64px;
  }
  // nav
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    transition: all 0.25s;
    li {
      margin-right: 27px;
      &:last-child {
        margin-right: 0;
      }
      a {
        font: 0.87rem/1 $font;
        text-decoration: none;
        color: $grey;
        &:hover {
          color: black;
        }
      }
    }
  }

  .header__sign {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.25s;
    .text {
      font: 0.87rem/1 $font;
      text-decoration: none;
      color: $grey;
      margin-right: 15px;
    }
    .login {
      width: 47px;
      height: 47px;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
      img {
        display: block;
      }
    }
    .reg {
      padding: 16px 30px;
      margin-right: 24px;
      transition: all 0.25s;
    }
  }

  .header__langcont {
    position: relative;
    .header__langitem {
      padding-left: 50px;
    }
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid $grey;
      position: absolute;
      right: 0;
      top: calc(50% - 4px);
    }
    select {
      display: block;
      border: none;
      background: transparent;
      // background: red;
      padding: 3px 15px 3px 5px;
      appearance: none;
      font: 800 0.87rem/1.25 $font;
      color: $grey;
      cursor: pointer;
      border: none;
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
      }
      option {
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media (max-width: 1440.98px) {
  .header__container {
    max-width: 99%;
    justify-content: space-between;

    .header__sign {
      .text {
        font: 0.87rem/1 $font;
        margin-right: 10px;
      }
      .login {
        margin-right: 10px;
      }
      .reg {
        padding: 16px 30px;
        margin-right: 10px;
      }
    }
  }
}
@media (max-width: 1200.98px) {
  .header__container {
    max-width: 97%;
    justify-content: space-between;
    .left {
      min-width: 50%;
    }
    .logo {
      margin-right: 14px;
      img {
        width: 120px;
      }
    }
    // nav
    .list {
      margin-bottom: 0px;
      li {
        margin-right: 12px;
        a {
          font: 0.87rem/1 $font;
        }
      }
    }

    .header__sign {
      .text {
        font: 0.87rem/1 $font;
        margin-right: 10px;
      }
      .login {
        margin-right: 10px;
      }
      .reg {
        padding: 16px 30px;
        margin-right: 10px;
      }
    }

    .header__langcont {
      position: relative;
      .header__langitem {
        padding-left: 50px;
      }
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid $grey;
        position: absolute;
        right: 0;
        top: calc(50% - 4px);
      }
      select {
        display: block;
        border: none;
        background: transparent;
        // background: red;
        padding: 3px 15px 3px 5px;
        appearance: none;
        font: 800 0.87rem/1.25 $font;
        color: $grey;
        cursor: pointer;
        border: none;
        &:focus {
          outline: none;
        }
        &:hover {
          cursor: pointer;
        }
        option {
          border: none;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
@media (max-width: 992.98px) {
  .header {
    width: 100%;
    padding: 5px 0;
    position: fixed;
    top: 0;
    &.modalinfo-opened,
    &.modal-opened {
      background: transparent;
      background: $bg;
      box-shadow: 1px -4px 19px 4px $bg;
      &.go-down {
        box-shadow: 0 0 0 #f3f4f7;
      }
      .header__mobile .close-all-modals {
        display: block;
        & .login__close {
          display: block;
        }
      }
      .login {
        display: none;
      }
    }

    &.mobile-menu-opened {
      bottom: 0;
      background: rgba(230, 239, 246, 0.95);
      overflow: hidden;
      .header__menu {
        margin-top: 0;
        top: 50px;
        opacity: 1;
        overflow: auto;
      }
    }
    &.go-down {
      padding: 5px 0;
      box-shadow: none;
    }
  }

  .header__container {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 100%;
    padding: 0 13px;
    position: relative;
    // background: rgba(0, 255, 0, 0.2);
  }
  .header__mobile {
    width: 100%;
    display: flex;
    background: $bg;
    // background: red;
    border-radius: 10px;
    justify-content: space-between;
    position: relative;
    z-index: 6;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      position: absolute;
      top: -5px;
      left: 0;
      background: blue;
      background: $white1;
    }
    .header__langcont {
      display: flex;
      justify-content: center;
      align-items: center;
      select {
        font: 800 1rem/1.25 $font;
      }
    }
    .close-all-modals {
      display: none;
    }
    .login {
      width: 47px;
      height: 47px;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;
      & .login__close {
        display: none;
      }
      &.active {
        & .login__burger {
          display: none;
        }
        & .login__close {
          display: block;
        }
      }
    }
  }

  .header__menu {
    display: block;
    height: 100%;
    height: auto;
    min-height: 100%;
    min-height: calc(100vh - 120px);
    min-height: 120px;
    padding: 0;
    // background: rgba(0, 0, 255, 0.2);
    position: relative;
    // margin-top: -200%;
    transition: all 0.25s ease-out;
    z-index: 4;
    position: absolute;
    top: 70px;
    top: -120vh;
    left: 15px;
    right: 15px;
    bottom: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sales {
    }
    // nav
    .list2 {
      padding: 20px 0;
      max-width: 100%;
      li {
        margin-bottom: 17px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: block;
          width: 100%;
          font: bold 0.87rem/1.3 $font;
          text-align: center;
          text-decoration: none;
          color: $grey;
          &:hover {
            color: black;
          }
        }
      }
    }
    // btn social
    .header__social {
      width: 100%;
      display: flex;
      justify-content: space-between;
      justify-content: space-around;
      margin-bottom: 27px;
      position: relative;
      z-index: 2;
      li {
        &:hover {
          a {
            transition: all 0.25s;
            span {
              transition: all 0.25s;
              box-shadow: 0px 0px 0px rgba(188, 202, 220, 1);
            }
            &.telegram {
              color: $tel;
              svg path {
                fill: $tel;
              }
            }
            &.youtube {
              color: $yt;
              svg path {
                fill: $yt;
              }
            }
          }
        }
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font: 600 0.75rem/1.3 $font;
          text-decoration: none;
          color: $grey2;
          transition: all 0.25s;
          span {
            display: flex;
            align-self: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: linear-gradient(147.5deg, #e9eff7 13.49%, #e2e8f0 97.05%);
            box-shadow: 7px 7px 17px rgba(188, 202, 220, 0.7), -7px -7px 17px rgba(243, 244, 247, 0.9);
            border-radius: 9px;
            margin-right: 8px;
            transition: all 0.25s;
            svg {
              width: 18px;
              height: 18px;
              display: block;
              align-self: center;
            }
          }
        }
      }
    }
    // btn red
    .header__btns {
      transition: all 0.5s;
      .mainslier__login {
        width: 100%;
        margin-bottom: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-right: 9px;
        }
      }
      .mainslier__reg {
        width: 100%;
      }
    }
  }
}
@media (max-width: 992.98px) and (orientation: landscape) {
  .header__menu {
    // nav
    .list2 {
      padding: 20px 0 0;
      li {
        margin-bottom: 10px;
        a {
          // font-size: 80%;
          // font: bold 10px/1.3 $font;
          // color: red;
        }
      }
    }
    .header__social {
      // background: red;
      li {
        a {
          font: 600 0.75rem/1.3 $font;
          span {
          }
        }
      }
    }
  }
}
@media (max-width: 768.98px) {
}
@media (max-width: 576.98px) {
}
@media (max-width: 400.98px) {
}
@media (max-width: 374.98px) {
}
@media (max-width: 354.98px) {
}
</style>
