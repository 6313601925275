<template>
  <section id="sliderOffers" data-aos="fade-left">
    <div class="container">
      <h2 class="title">{{ $filters.localizeFilter('offers_slider_title') }}</h2>
    </div>
    <div class="container-fluid sliderOffers__cont">
      <swiper ref="mySwiper11" @swiper="onSwiper" class="sliderOffers__slider">
        <swiper-slide>
          <div class="swiper__item">
            <img src="@/assets/swiper__img1.svg" alt class="swipper__img" />
            <h4 class="swiper__title">{{ $filters.localizeFilter('offers_slider_slide1-text1') }}</h4>
            <p class="swiper__text1">{{ $filters.localizeFilter('offers_slider_slide1-text2') }}</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper__item">
            <img src="@/assets/swiper__img2.svg" alt class="swipper__img" />
            <h4 class="swiper__title">{{ $filters.localizeFilter('offers_slider_slide2-text1') }}</h4>
            <p class="swiper__text1">{{ $filters.localizeFilter('offers_slider_slide2-text2') }}</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper__item">
            <img src="@/assets/swiper__img3.svg" alt class="swipper__img long" />
            <h4 class="swiper__title">{{ $filters.localizeFilter('offers_slider_slide3-text1') }}</h4>
            <p class="swiper__text1">{{ $filters.localizeFilter('offers_slider_slide3-text2') }}</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper__item">
            <img src="@/assets/swiper__img4.svg" alt class="swipper__img" />
            <h4 class="swiper__title">{{ $filters.localizeFilter('offers_slider_slide4-text1') }}</h4>
            <p class="swiper__text1">{{ $filters.localizeFilter('offers_slider_slide4-text2') }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'slider',
  data() {
    return {
      swiper: null,
      swiperOptions: null
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.setAdaptiveSwiperOptions();
    },
    showNext() {
      this.$refs.carousel.next();
    },
    setAdaptiveSwiperOptions() {
      const w = window.innerWidth;
      let options;

      if (w >= 1200) {
        options = {
          slidesPerView: 4,
          spaceBetween: 20
        };
      } else if (w >= 992) {
        options = {
          slidesPerView: 3,
          spaceBetween: 20
        };
      } else if (w >= 576) {
        options = {
          slidesPerView: 2,
          spaceBetween: 20
        };
      } else {
        options = {
          slidesPerView: 2,
          spaceBetween: 5
        };
      }

      // set data in reactive variables
      this.swiperOptions = {
        autoplay: {
          delay: 44000
        },
        offsetPxBefore: 1,
        loop: true,
        ...options
      };

      this.swiper.params = Object.assign({}, this.swiper.params, this.swiperOptions);
    }
  },
  components: {
    Swiper,
    SwiperSlide
  }
};
</script>
<style lang="scss" scoped>
#sliderOffers {
}

.title {
  margin-bottom: 0px;
  margin-left: -15px;
}
.sliderOffers__cont {
}
.sliderOffers__slider {
  width: calc(100% + 350px);
  margin-left: -350px;
}

.swiper-slide {
  padding: 40px 0 70px;
  &.swiper-slide-next .swiper__item {
    background: linear-gradient(166.46deg, #e9eff7 13.49%, #e2e8f0 97.05%);
    box-shadow: 23px 18px 60px #bccadc, -21px -10px 32px #f3f4f7;
    transition: all 0.25s;
    z-index: 2;
  }
}

.swiper__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 10px 0px 100px;
  min-height: 140px;
  background: $white1;
  border-radius: 9px;
  position: relative;
  z-index: 1;
  transition: all 0.25s;
  box-shadow: 0 0 0 transparent;
  cursor: default;
  // &:hover {
  //   background: linear-gradient(166.46deg, #e9eff7 13.49%, #e2e8f0 97.05%);
  //   box-shadow: 23px 18px 60px #bccadc, -21px -10px 32px #f3f4f7;
  //   transition: all 0.25s;
  //   z-index: 2;
  // }
  .swipper__img {
    width: 48px;
    height: 48px;
    position: absolute;
    top: calc(50% - 24px);
    left: 26px;
    &.long {
      top: calc(50% - 20px);
    }
  }
  .swiper__title {
    width: 100%;
    font: bold 1rem/1.28 $font;
    color: $grey2;
    margin-bottom: 8px;
  }
  .swiper__text1 {
    width: 100%;
    min-height: 40px;
    font: 0.87rem/1.25 $font;
    color: $grey2;
  }
}

@media (max-width: 1200.98px) {
}
@media (max-width: 992.98px) {
  .sliderOffers__cont {
    max-width: 100%;
    overflow: hidden;
    margin-bottom: -28px;
  }
  .sliderOffers__slider {
    width: calc(100% + 50px);
    margin-left: 0px;
  }
  .title {
    text-align: center;
    margin-left: 0px;
  }

  .swiper-slide {
    padding: 20px 0 34px;
    padding: 20px 0 70px;
    // background: red;
  }
  .swiper-slide-active .swiper__item {
    box-shadow: -21px -10px 32px #f3f4f7, 23px 18px 60px #bccadc;
    opacity: 1;
  }
  .swiper__item {
    padding: 20px 10px 0;
    min-height: 140px;
    opacity: 0.5;
    .swipper__img {
      position: relative;
      top: 0;
      left: 0;
      margin-bottom: 10px;
      &.long {
        top: 0;
      }
    }
    .swiper__title {
      width: 100%;
      font: bold 1rem/1.28 $font;
      margin-bottom: 8px;
    }
    .swiper__text1 {
      width: 100%;
      min-height: 40px;
      font: 0.87rem/1.25 $font;
    }
  }
}
@media (max-width: 768.98px) {
}
@media (max-width: 576.98px) {
  .sliderOffers__slider {
    width: calc(100% + 100px);
    margin-left: 0px;
  }

  .swiper__item {
    display: block;
    padding: 20px 10px 28px 10px;
    min-height: 150px;
    .swipper__img {
      position: relative;
      top: 0;
      left: 0;
      margin-bottom: 10px;
      &.long {
        top: calc(50% - 20px);
      }
    }
    .swiper__title {
      width: 100%;
      font: bold 0.87rem/1.28 $font;
      margin-bottom: 8px;
    }
    .swiper__text1 {
      width: 100%;
      min-height: 40px;
      font: 0.81rem/1.25 $font;
    }
  }
}
@media (max-width: 354.98px) {
}
</style>
