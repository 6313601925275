<template>
  <div :class="{ loading: !data_ }" class="sales__container">
    <Spiner v-if="!data_" />

    <transition name="fade">
      <div class="swiper__price" v-if="data_ && data_.banner" @click.prevent="whichModal">
        <img v-if="data_.banner.icon" :src="data_.banner.icon[lang]" alt="icon" class="sales__icon" />
        <p>
          <b v-if="data_.banner.description" v-html="data_.banner.description[lang]"></b>

          <!-- <router-link v-if="data.banner.url && data.banner.url[lang] && data.banner.url[lang] !== null" :to="data.banner.url[lang]">Подробнее</router-link> -->
          <a>{{ $filters.localizeFilter('more') }}</a>
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import Spiner from '@/components/Spiner';

export default {
  props: ['data'],
  data() {
    return {};
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
    data_ () {
      if(this.data) return this.data
      else return this.$store.state.sales.defaultTemplate
    }
  },
  methods: {
    whichModal() {
      if (this.data_.isDefault) {
        this.launchModal('modal__info');
      } else {
        this.launchModal('modal__sales');
      }
    }
  },
  components: {
    Spiner
  }
};
</script>

<style lang="scss" scoped>
.sales__container {
  width: 86%;
  min-height: 86px;
  min-height: 112px;
}
.loading {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.swiper__price {
  display: block;
  text-decoration: none;
  width: 87%;
  width: 470px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 21px 44px 24px 26px;
  background: $white1;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  span:not(.in) {
    font: 800 2.8rem/0.9 $font;
    text-align: center;
    color: $orange;
    b {
      font-size: 1.125rem;
      display: block;
    }
  }
  p {
    padding-left: 18px;
    font: 600 0.87rem/1.3 $font;
    color: $grey;
    width: calc(100% - 80px);
    
    span {
      font-weight: bold;
      color: $grey;
    }
    a {
      font-weight: bold;
      text-decoration: none;
      color: $grey2;
    }
  }
  .sales__icon {
    display: block;
    max-width: 80px;
    max-height: 67px;
  }
}
@media (max-width: 992.98px) {
  .swiper__price {
    margin: 0 auto;
  }
  .loading {
    width: 100%;
    min-height: 86px;
    justify-content: center;
  }
}
@media (max-width: 576.98px) {
  .sales__container {
    width: 100%;
  }
  .swiper__price {
    width: 100%;
    padding: 10px 5px;
    justify-content: space-between;
    align-items: center;
    background: $white1;
    span:not(.in) {
      font: 800 2rem/1 $font;
      text-align: center;
      b {
        font-size: 1.125rem;
      }
    }
    p {
      padding-left: 8px;
      font: 600 0.75rem/1.3 $font;
    }
  }
}
</style>
