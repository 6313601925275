<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col">
          <img src="@/assets/footer__logo.svg" alt="logotype" class="footer__logo d-none d-lg-block" />
          <p class="footer__text">{{ $filters.localizeFilter('footer_title') }}</p>
          <ul class="footer__list">
            <li>
              <a href="//www.youtube.com/channel/UCv6MaaPIPZg8qRGYYU4DKzw" target="_blank">
                <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M25.6215 3.17334C25.3298 2.08893 24.4748 1.23411 23.3906 0.942116C21.4098 0.400146 13.4865 0.400146 13.4865 0.400146C13.4865 0.400146 5.56353 0.400146 3.58274 0.921568C2.51935 1.21325 1.64352 2.08909 1.35183 3.17334C0.830566 5.15396 0.830566 9.26153 0.830566 9.26153C0.830566 9.26153 0.830566 13.3898 1.35183 15.3497C1.64383 16.434 2.4985 17.2888 3.5829 17.5808C5.58438 18.1229 13.4868 18.1229 13.4868 18.1229C13.4868 18.1229 21.4098 18.1229 23.3906 17.6015C24.475 17.3097 25.3298 16.4548 25.6218 15.3706C26.1429 13.3898 26.1429 9.28239 26.1429 9.28239C26.1429 9.28239 26.1638 5.15396 25.6215 3.17334ZM10.9639 13.0562V5.46682L17.5525 9.26153L10.9639 13.0562Z"
                    fill="#6C7D96"
                  />
                </svg>
                youtube_traforce
              </a>
            </li>
            <li>
              <a href="//t.me/dating_traf" target="_blank">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.06274 10.3858L6.76499 14.5738C7.19099 14.5738 7.37549 14.3908 7.59674 14.1711L9.59399 12.2623L13.7325 15.2931C14.4915 15.7161 15.0262 15.4933 15.231 14.5948L17.9475 1.86581L17.9482 1.86506C18.189 0.74306 17.5425 0.30431 16.803 0.57956L0.835488 6.69281C-0.254262 7.11581 -0.237762 7.72331 0.650238 7.99856L4.73249 9.26831L14.2147 3.33506C14.661 3.03956 15.0667 3.20306 14.733 3.49856L7.06274 10.3858Z"
                    fill="#6C7D96"
                  />
                </svg>
                @dating_traf
              </a>
            </li>
            <li>
              <a href="//t.me/traforce" target="_blank">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.06274 10.3858L6.76499 14.5738C7.19099 14.5738 7.37549 14.3908 7.59674 14.1711L9.59399 12.2623L13.7325 15.2931C14.4915 15.7161 15.0262 15.4933 15.231 14.5948L17.9475 1.86581L17.9482 1.86506C18.189 0.74306 17.5425 0.30431 16.803 0.57956L0.835488 6.69281C-0.254262 7.11581 -0.237762 7.72331 0.650238 7.99856L4.73249 9.26831L14.2147 3.33506C14.661 3.03956 15.0667 3.20306 14.733 3.49856L7.06274 10.3858Z"
                    fill="#6C7D96"
                  />
                </svg>
                @traforce
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-3">
          <ul class="footer__list2">
            <li>
              <a href="milto:info@traforce.com">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                    <path d="M12.5947 9.59733L19 13.6467V5.37695L12.5947 9.59733Z" fill="#6C7D96" />
                    <path d="M0 5.37695V13.6467L6.40538 9.59733L0 5.37695Z" fill="#6C7D96" />
                    <path
                      d="M17.8125 2.96875H1.18752C0.594953 2.96875 0.124703 3.4105 0.0356407 3.97931L9.50001 10.2149L18.9644 3.97931C18.8753 3.4105 18.4051 2.96875 17.8125 2.96875Z"
                      fill="#6C7D96"
                    />
                    <path
                      d="M11.5068 10.3147L9.82651 11.4214C9.72676 11.4867 9.61395 11.5188 9.49995 11.5188C9.38595 11.5188 9.27314 11.4867 9.17339 11.4214L7.49308 10.3135L0.0379524 15.029C0.12939 15.5931 0.597265 16.0313 1.18745 16.0313H17.8125C18.4026 16.0313 18.8705 15.5931 18.962 15.029L11.5068 10.3147Z"
                      fill="#6C7D96"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="19" height="19" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                info@traforce.com
              </a>
            </li>
            <li>
              <a href="skype:panamare13?chat">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.5105 10.3033C17.7416 4.77125 12.8952 -0.254376 7.20374 0.710374C4.11399 -1.20708 -7.62939e-06 0.959707 -7.62939e-06 4.63596C-7.62939e-06 5.49092 0.235867 6.29133 0.645992 6.98125C-0.497966 12.5261 4.38812 17.4795 10.0746 16.4488C14.0944 18.5745 18.5364 14.3352 16.5105 10.3033ZM11.1003 13.6396C9.60712 14.2537 7.14991 14.2572 5.66312 13.4788C3.54308 12.3483 3.16624 9.80325 4.99516 9.80325C6.37358 9.80325 5.93724 11.4105 7.32912 12.0657C7.96803 12.361 9.34149 12.3915 10.1398 11.8532C10.9282 11.3255 10.8559 10.4953 10.4274 10.0986C9.29191 9.05029 6.04278 9.46679 4.55741 7.6315C3.91212 6.83533 3.79028 5.43071 4.58362 4.41212C5.96628 2.63279 10.0236 2.52442 11.7682 3.86812C13.379 5.11479 12.9441 6.76946 11.6443 6.76946C10.081 6.76946 10.9027 4.72379 8.37887 4.72379C6.54924 4.72379 5.82958 6.025 7.11803 6.65825C8.86478 7.52596 13.316 7.23625 13.316 10.627C13.3124 12.038 12.4475 13.0899 11.1003 13.6396Z"
                    fill="#6C7D96"
                  />
                </svg>
                Skype
              </a>
            </li>
            <li>
              <a href="//t.me/JulVel" target="_blank">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.06274 10.3858L6.76499 14.5738C7.19099 14.5738 7.37549 14.3908 7.59674 14.1711L9.59399 12.2623L13.7325 15.2931C14.4915 15.7161 15.0262 15.4933 15.231 14.5948L17.9475 1.86581L17.9482 1.86506C18.189 0.74306 17.5425 0.30431 16.803 0.57956L0.835488 6.69281C-0.254262 7.11581 -0.237762 7.72331 0.650238 7.99856L4.73249 9.26831L14.2147 3.33506C14.661 3.03956 15.0667 3.20306 14.733 3.49856L7.06274 10.3858Z"
                    fill="#6C7D96"
                  />
                </svg>
                Telegram
              </a>
            </li>
            <li>
              <a href="skype:panamare13?chat" class="footer__get-question">{{ $filters.localizeFilter('footer_has_question') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'myfooter'
};
</script>

<style lang="scss" scoped>
footer {
  background: $grey2;
  padding: 34px 0;
  .footer__logo {
    display: block;
    max-width: 100%;
    margin-bottom: 26px;
  }
  .footer__text {
    font: bold 0.87rem/1.2 $font;
    color: $grey;
    margin-bottom: 20px;
  }
  .footer__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    li {
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font: 600 0.87rem/1.2 $font;
        text-decoration: none;
        color: $grey;
        svg {
          display: block;
          margin-right: 12px;
          transition: all 0.25s;
          & path {
            transition: all 0.25s;
          }
        }
        &:hover {
          color: white;
          transition: all 0.25s;
          svg {
            transition: all 0.25s;
            & path {
              fill: white;
              transition: all 0.25s;
            }
          }
        }
      }
    }
  }
  .footer__list2 {
    li {
      margin-bottom: 6px;
      &:nth-child(3) {
        margin-bottom: 17px;
      }
      &:nth-child(4) {
        margin-bottom: 0;
      }
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font: 600 0.87rem/1.2 $font;
        text-decoration: none;
        color: $grey;
        position: relative;
        padding-left: 33px;
        svg {
          display: block;
          margin-right: 12px;
          position: absolute;
          left: 0;
          top: 0;
          transition: all 0.25s;
          & path {
            transition: all 0.25s;
          }
        }
        &:hover {
          color: white;
          transition: all 0.25s;
          svg {
            transition: all 0.25s;
            & path {
              fill: white;
              transition: all 0.25s;
            }
          }
        }
      }
      .footer__get-question {
        display: block;
        width: 210px;
        max-width: 100%;
        background: $grey;
        border-radius: 9px;
        padding: 16px;
        border: none;
        font: bold 0.75rem/1.3 $font;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        text-align: center;
        color: white;
        &:hover {
          background: $white1;
          color: $black;
        }
      }
    }
  }
}

@media (max-width: 1200.98px) {
}
@media (max-width: 992.98px) {
  footer {
    padding: 34px 0;
    .footer__logo {
      margin-bottom: 26px;
    }
    .footer__text {
      font: bold 0.87rem/1.2 $font;
      text-align: center;
      padding: 0 45px;
      margin-bottom: 20px;
    }

    .footer__list {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 37px;
      li {
        margin-right: 0px;
        margin-bottom: 12px;
        a {
          font: 600 0.87rem/1.2 $font;
          svg {
            margin-right: 12px;
          }
        }
      }
    }
    .footer__list2 {
      flex-direction: column;
      justify-content: center;
      li {
        margin-bottom: 7px;
        &:nth-child(3) {
          margin-bottom: 32px;
        }
        &:nth-child(4) {
          margin-bottom: 0;
        }
        a {
          justify-content: center;
          font: 600 0.87rem/1.2 $font;
          padding-left: 0px;
          svg {
            margin-right: 14px;
            position: relative;
            left: auto;
            right: auto;
          }
        }
        .footer__get-question {
          width: 210px;
          margin: 0 auto;
          padding: 16px;
          font: bold 0.75rem/1.3 $font;
        }
      }
    }
  }
}
@media (max-width: 768.98px) {
}
@media (max-width: 576.98px) {
}
@media (max-width: 400.98px) {
}
@media (max-width: 374.98px) {
  footer {
    padding: 26px 0 34px;
    .footer__text {
      text-align: center;
      padding: 0 35px;
    }
  }
}
@media (max-width: 354.98px) {
}
</style>
