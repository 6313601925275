<template>
  <section
    id="mainslider"
    :class="{
      slide1: swiperMyOptions.currentSlide === 0 && width >= 992,
      slide2: swiperMyOptions.currentSlide === 1 && width >= 992,
      slide3: swiperMyOptions.currentSlide === 2 && width >= 992,
      slide4: swiperMyOptions.currentSlide === 3 && width >= 992
    }"
  >
    <div class="container">
      <!-- slider lg -->
      <swiper
        v-if="width >= 992"
        ref="mySwiper"
        :key="swiperDate"
        @swiper="onSwiper"
        @slideChange="swiperChange"
        @click="nextSlide"
        class="swiper-container"
      >
        <swiper-slide v-for="item of swiperSlidesText" :key="item.title">
          <div class="swiper__item">
            <div class="swiper__left">
              <h1 class="swiper__title">{{ $filters.localizeFilter('title') }}</h1>

              <h5 class="swiper__subtitle">{{ $filters.localizeFilter(item.title) }}</h5>

              <p class="swiper__text1">{{ $filters.localizeFilter(item.description) }}</p>

              <ul class="swiper__social">
                <li>
                  <a href="//t.me/traforce" class="telegram" target="_blank">
                    <span>
                      <!-- <img src="@/assets/telegram.svg" alt /> -->
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                          <path
                            d="M9.417 15.181L9.02 20.765C9.588 20.765 9.834 20.521 10.129 20.228L12.792 17.683L18.31 21.724C19.322 22.288 20.035 21.991 20.308 20.793L23.93 3.82104L23.931 3.82004C24.252 2.32404 23.39 1.73904 22.404 2.10604L1.11399 10.257C-0.339005 10.821 -0.317005 11.631 0.866995 11.998L6.31 13.691L18.953 5.78004C19.548 5.38604 20.089 5.60404 19.644 5.99804L9.417 15.181Z"
                            fill="#6C7D96"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    @traforce
                  </a>
                </li>
                <li>
                  <a href="//www.youtube.com/channel/UCv6MaaPIPZg8qRGYYU4DKzw" class="youtube" target="_blank">
                    <span>
                      <!-- <img src="@/assets/youtube.svg" alt /> -->
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M25.6215 7.17327C25.3298 6.08887 24.4748 5.23405 23.3906 4.94205C21.4098 4.40009 13.4865 4.40009 13.4865 4.40009C13.4865 4.40009 5.56353 4.40009 3.58274 4.92151C2.51935 5.21319 1.64352 6.08903 1.35183 7.17327C0.830566 9.1539 0.830566 13.2615 0.830566 13.2615C0.830566 13.2615 0.830566 17.3897 1.35183 19.3497C1.64383 20.4339 2.4985 21.2887 3.5829 21.5807C5.58438 22.1229 13.4868 22.1229 13.4868 22.1229C13.4868 22.1229 21.4098 22.1229 23.3906 21.6014C24.475 21.3096 25.3298 20.4548 25.6218 19.3705C26.1429 17.3897 26.1429 13.2823 26.1429 13.2823C26.1429 13.2823 26.1638 9.1539 25.6215 7.17327ZM10.9639 17.0562V9.46676L17.5525 13.2615L10.9639 17.0562Z"
                          fill="#6C7D96"
                        />
                      </svg>
                    </span>
                    youtube_traforce
                  </a>
                </li>
              </ul>

              <Sales :data="saleData" />
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <!-- slider sm -->
      <swiper v-else ref="mySwiper" :key="swiperDate" @swiper="onSwiper" @slideChange="swiperChange">
        <swiper-slide v-for="item in swiperSlidesText" :key="item.title">
          <div class="swiper__item">
            <div class="swiper__left">
              <h1 class="swiper__title">{{ $filters.localizeFilter('title') }}</h1>
              <img :src="item.img" alt="illustration" class="swiper__img" />
              <h5 class="swiper__subtitle">{{ $filters.localizeFilter(item.title) }}</h5>
              <p class="swiper__text1">{{ $filters.localizeFilter(item.description) }}</p>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <!-- pagination for sliders: lg & sm -->
      <SwiperPagination
        class="mainslider__pagination"
        :currentSlide="swiperMyOptions.currentSlide"
        :allSlides="swiperMyOptions.allSlides"
        :shiftSlides="swiperMyOptions.shiftSlides"
        @ChangeSlide="swiperSlideTo($event)"
      />

      <div class="ms" v-if="width < 992">
        <div class="mainslider__btns-sm">
          <button class="btn-orange mainslier__reg" @click="goToEvents">{{ $filters.localizeFilter('reg') }}</button>
          <button class="btn-white  mainslier__login" @click="login">
            <img src="@/assets/login-icon.svg" alt="icon" />
          </button>
        </div>

        <Sales :data="saleData" />
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperPagination from '@/components/SwiperPagination';
import Sales from '@/components/Sales';

// import { RepositoryFactory } from '@/repositories/RepositoryFactory';
// const SalesRepository = RepositoryFactory.get('sales');

export default {
  name: 'main-slider',
  data() {
    return {
      swiperSlidesText: [
        {
          title: 'slide1_title',
          img: '/img/slider__img1.svg',
          description: 'slide1_description'
        },
        {
          title: 'slide2_title',
          img: '/img/slider__img2.svg',
          description: 'slide2_description'
        },
        {
          title: 'slide3_title',
          img: '/img/slider__img3.svg',
          description: 'slide3_description'
        },
        {
          title: 'slide4_title',
          img: '/img/slider__img4.svg',
          description: 'slide4_description'
        }
      ],
      swiper: null,
      swiperDate: Date.now(),
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 20,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 8000
        },
        loop: true
      },
      swiperMyOptions: {
        currentSlide: 0,
        allSlides: 4,
        shiftSlides: 2
      },
      saleData: null,
      width: window.innerWidth
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.swiper.params = Object.assign({}, this.swiper.params, this.swiperOptions);
      this.swiper.update();
    },
    swiperChange() {
      this.swiperMyOptions.currentSlide = +this.swiper.realIndex;
      this.swiperMyOptions.allSlides = +this.swiper.slides.length;
    },
    swiperSlideTo(slideTo) {
      this.swiper.slideTo(slideTo, 400);
    },
    login() {
      const link = this.$store.state.linkLogIn;
      window.open(link, '_blank');
    },
    nextSlide(e) {
      // if (!e.target.closest('.sales__container')) {
        this.swiper.slideNext();
      // }
    },
    goToEvents: function () {
      if(this.$store.state.isDev) return this.launchModal('modal__reg')
      else location.href='//affiliate.traforce.com/v2/sign/up'
    }
  },
  computed: {
    activeIndex() {
      return this.swiper.activeIndex;
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    SwiperPagination,
    Sales
  },
  async created() {
    const res = await this.$store.dispatch('fetchSalesData');
    if (res && res.status === 200) {
      this.saleData = res.data;
    } else {
      this.saleData = this.$store.state.sales.defaultTemplate;
    }
  },
  mounted() {
    // set length of slides and current index of slide
    this.swiperChange();
  }
};
</script>

<style lang="scss" scoped>
#mainslider {
  padding: 17px 0 170px 0;
  padding: 17px 0 70px 0;
  padding-top: 40px;
  position: relative;
  .container {
    padding: 0;
  }
  &::before {
    content: '';
    display: block;
    width: 50%;
    background: url(../assets/mainSlider__whitebg.svg) no-repeat center center/cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 30px;
  }
  &::after {
    content: '';
    display: block;
    width: 50%;
    position: absolute;
    top: -20px;
    right: 0;
    bottom: 0;
    transition: all 0.25s;
    transition: all 0.5s;
  }
  &.slide1:after {
    background: url(../assets/slider__img1.svg) no-repeat center center/auto;
    top: -40px;
    right: 0;
    bottom: 0;
  }
  &.slide2:after {
    background: url(../assets/slider__img2.svg) no-repeat center center/auto;
    top: -22px;
    right: 22px;
    bottom: 0;
  }
  &.slide3:after {
    background: url(../assets/slider__img3.svg) no-repeat center center/auto;
    top: -32px;
    right: 42px;
    transform: rotate(2deg);
    bottom: 0;
  }
  &.slide4:after {
    background: url(../assets/slider__img4.svg) no-repeat center center/auto;
    top: -135px;
    right: 62px;
    bottom: 0;
  }
}

.swiper-container {
  position: relative;
  padding-bottom: 50px;
}

// slide
.swiper__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .swiper__left {
    width: 50%;
    &.second {
      min-height: 420px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .swiper__title {
        font: bold 1.5rem/1.05 $font;
        text-align: left;
        color: $grey2;
        margin-bottom: 14px;
      }
      .swiper__text1 {
        font: 1rem/1.3 $font;
        color: $grey;
      }
    }
    .swiper__title {
      font: bold 2.5rem/1.05 $font;
      color: $grey2;
      margin-bottom: 41px;
      margin-bottom: 32px;
    }
    .swiper__subtitle {
      font: 600 1.5rem/2.125 $font;
      color: $grey2;
      margin-bottom: 14px;
      margin-bottom: 10px;
    }
    .swiper__text1 {
      width: 65%;
      font: 1rem/1.3 $font;
      color: $grey;
      margin-bottom: 26px;
      margin-bottom: 46px;
    }
    .swiper__social {
      display: flex;
      justify-content: space-between;
      justify-content: flex-start;
      margin-bottom: 27px;
      margin-bottom: 45px;
      li {
        margin-right: 43px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          a {
            transition: all 0.25s;
            span {
              transition: all 0.25s;
              box-shadow: 0px 0px 0px rgba(188, 202, 220, 1);
            }
            &.telegram {
              color: $tel;
              svg path {
                fill: $tel;
              }
            }
            &.youtube {
              color: $yt;
              svg path {
                fill: $yt;
              }
            }
          }
        }
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font: 600 1rem/1.37 $font;
          text-decoration: none;
          color: $grey2;
          transition: all 0.25s;
          span {
            display: flex;
            align-self: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            background: linear-gradient(147.5deg, #e9eff7 13.49%, #e2e8f0 97.05%);
            box-shadow: 7px 7px 17px rgba(188, 202, 220, 0.7), -7px -7px 17px rgba(243, 244, 247, 0.9);
            border-radius: 9px;
            margin-right: 25px;
            transition: all 0.25s;
            svg {
              display: block;
              align-self: center;
            }
          }
        }
      }
    }
  }
}

// pagination
.mainslider__pagination {
  position: relative;
  bottom: -100px;
  bottom: 0px;
}

@media (max-width: 1440.98px) {
  #mainslider {
    .cntainer {
      max-width: 1172px;
    }
    &::before {
      width: 50%;
      background: url(../assets/mainSlider__whitebg.svg) no-repeat center center/cover;
      top: 0;
      right: 0;
      bottom: 30px;
    }
    &::after {
      width: 50%;
      top: -20px;
      right: 0;
      bottom: 0;
    }
    &.slide1:after {
      background: url(../assets/slider__img1.svg) no-repeat center center/auto;
      top: -70px;
      right: -40px;
    }
    &.slide2:after {
      background: url(../assets/slider__img2.svg) no-repeat center center/auto;
      top: -60px;
      right: -20px;
    }
    &.slide3:after {
      background: url(../assets/slider__img3.svg) no-repeat center center/auto;
      top: -30px;
      right: 0px;
      transform: rotate(2deg);
    }
    &.slide4:after {
      background: url(../assets/slider__img4.svg) no-repeat center center/auto;
      transform: scale(0.95);
      top: -135px;
      right: 12px;
    }
  }
  // slide
  .swiper__item {
    .swiper__left {
      .swiper__title {
        font: bold 2.5rem/1.05 $font;
        margin-bottom: 32px;
      }
      .swiper__subtitle {
        font: 600 1.5rem/2.125 $font;
        margin-bottom: 14px;
      }
      .swiper__text1 {
        width: 65%;
        font: 1rem/1.3 $font;
        margin-bottom: 36px;
      }
      .swiper__social {
        margin-bottom: 27px;
        li {
          margin-right: 43px;
        }
      }
    }
  }
}
@media (max-width: 1200.98px) {
}
@media (max-width: 992.98px) {
  #mainslider {
    padding: 0 0 50px 0;
    position: relative;
    &:before,
    &:after {
      display: none;
    }
    .container {
      max-width: auto;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  // btns
  .mainslider__btns-sm {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    .mainslier__reg {
      width: calc(100% - 50px - 10px);
    }
    .mainslier__login {
      width: 48px;
    }
  }

  .swiper-container {
    position: relative;
    padding-bottom: 26px;
  }

  // slide
  .swiper__item {
    width: 100%;
    min-height: 0px;
    .swiper__left {
      width: 100%;
      .swiper__title {
        font: bold 1.7rem/1.05 $font;
        text-align: center;
        margin-bottom: 0px;
      }
      .swiper__img {
        display: block;
        max-width: 100%;
        height: 300px;
        margin: 0 auto;
        margin-bottom: 16px;
      }
      .swiper__subtitle {
        font: 600 1.5rem/1 $font;
        text-align: center;
        margin-bottom: 8px;
      }
      .swiper__text1 {
        width: 100%;
        font: 1rem/1.3 $font;
        text-align: center;
        margin-bottom: 0px;
      }
    }
  }

  // pagination
  .mainslider__pagination {
    width: 100px;
    // background: red;
    margin: 0 auto;
    bottom: 0px;
    margin-bottom: 20px;
  }
}
@media (max-width: 768.98px) {
}
@media (max-width: 576.98px) {
  // slide
  .swiper__item {
    min-height: 0px;
    .swiper__left {
      .swiper__title {
        font: bold 1.125rem/1.2 $font;
        text-align: center;
        margin-bottom: 0px;
      }
      .swiper__img {
        display: block;
        max-width: 100%;
        height: 300px;
        margin: 0 auto;
        margin-bottom: 16px;
      }
      .swiper__subtitle {
        font: 600 1rem/1 $font;
        font: 600 1.125rem/1 $font;
        text-align: center;
        margin-bottom: 8px;
      }
      .swiper__text1 {
        font: 0.81rem/1.3 $font;
        font: 1rem/1.3 $font;
        text-align: center;
        margin-bottom: 0px;
      }
    }
  }
}
@media (max-width: 354.98px) {
}
</style>
