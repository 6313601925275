<template>
  <div>
    <ul class="pagination__btns">
      <li v-for="index of allSlides - shiftSlides" :key="index" :value="index">
        <button :class="{ active: currentSlide === index - 1 }" @click="setSlide(index - 1)">&nbsp;</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'pagination-component',
  props: {
    currentSlide: {
      type: Number,
      requied: true
    },
    allSlides: {
      type: Number,
      requied: true
    },
    shiftSlides: {
      type: Number,
      requied: true,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    setSlide(slideTo) {
      this.$emit('ChangeSlide', slideTo);
    }
  },
  mounted() {
    // console.log(this.currentSlide, this.allSlides, this.shiftSlides);
  }
};
</script>

<style lang="scss" scoped>
.pagination__btns {
  width: 200px;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  li {
    display: block;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    &:first-child button.active {
      margin-right: 25px;
      margin-left: 5px;
      transition: all 0.25s;
      &::before {
        opacity: 0;
        transition: all 0.25s;
      }
      &::after {
        right: -18px;
        left: auto;
        transition: all 0.25s;
      }
    }
    &:last-child button.active {
      margin-right: 5px;
      margin-left: 25px;
      transition: all 0.25s;
      &::before {
        right: auto;
        left: -19px;
        transition: all 0.25s;
      }
      &::after {
        opacity: 0;
        transition: all 0.25s;
      }
    }
    button {
      display: block;
      background: $grey3;
      border: none;
      width: 12px;
      height: 12px;
      padding: 0px;
      border-radius: 50%;
      margin: 0 3px;
      position: relative;
      z-index: 2;
      box-sizing: border-box;
      transition: all 0.25s;
      &.active {
        background: $grey;
        margin-right: 25px;
        margin-left: 25px;
        &::before,
        &::after {
          content: '';
          opacity: 1;
          z-index: -1;
          transition: all 0.25s;
          animation: blick 0.25s ease-in-out;
        }
        &::before {
          display: block;
          width: 9px;
          height: 2px;
          border-radius: 3px;
          background: $grey;
          position: absolute;
          top: calc(50% - 1px);
          left: -18px;
        }
        &::after {
          display: block;
          width: 9px;
          height: 2px;
          border-radius: 3px;
          background: $grey;
          position: absolute;
          top: calc(50% - 1px);
          right: -18px;
        }
      }
    }
  }
}
@keyframes blick {
  0%,
  70% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
</style>
