<template>
  <section id="support">
    <div class="container">
      <div class="row">
        <div class="col support__col">
          <h2 class="title" v-html="$filters.localizeFilter('support_title')"></h2>
          <p class="support__text1">
            {{ $filters.localizeFilter('support_text1') }}
          </p>

          <div v-if="width >= 992" class="support__container-lg">
            <div class="support__widecont right support__top">
              <img src="@/assets/support__img1.png" alt="icon" class="support__imgs" data-aos="fade-right" />
              <div class="support__contin">
                <h4 class="support__subtitle">{{ $filters.localizeFilter('support_subtitle1') }}</h4>
                <p class="support__text2">
                  {{ $filters.localizeFilter('support_subtext1') }}
                </p>
                <a href="//www.youtube.com/channel/UCv6MaaPIPZg8qRGYYU4DKzw" target="_blank" class="support__btn-yt">
                  <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24.7909 3.46342C24.4992 2.39628 23.6442 1.55507 22.56 1.26772C20.5792 0.734375 12.6559 0.734375 12.6559 0.734375C12.6559 0.734375 4.73296 0.734375 2.75218 1.2475C1.68879 1.53454 0.812954 2.39643 0.521267 3.46342C0 5.41253 0 9.45471 0 9.45471C0 9.45471 0 13.5173 0.521267 15.446C0.813263 16.513 1.66793 17.3542 2.75233 17.6416C4.75382 18.175 12.6562 18.175 12.6562 18.175C12.6562 18.175 20.5792 18.175 22.56 17.6619C23.6444 17.3747 24.4992 16.5335 24.7912 15.4665C25.3123 13.5173 25.3123 9.47524 25.3123 9.47524C25.3123 9.47524 25.3332 5.41253 24.7909 3.46342V3.46342ZM10.1333 13.189V5.7204L16.7219 9.45471L10.1333 13.189Z"
                      fill="#BCCADC"
                    />
                  </svg>
                  youtube_traforce</a
                >
              </div>
            </div>

            <div class="support__widecont left support__middle">
              <div class="support__contin">
                <h4 class="support__subtitle">{{ $filters.localizeFilter('support_subtitle2') }}</h4>
                <ul class="support__list">
                  <li>{{ $filters.localizeFilter('support_subtext2-1') }}</li>
                  <li>{{ $filters.localizeFilter('support_subtext2-2') }}</li>
                </ul>
              </div>
              <img :src="imgType" alt="" class="support__imgs" data-aos="fade-left" />
            </div>

            <div class="support__widecont right support__bottom">
              <img src="@/assets/support__img3.svg" alt="" class="support__imgs" data-aos="fade-right" />
              <div class="support__contin">
                <h4 class="support__subtitle">{{ $filters.localizeFilter('support_subtitle3') }}</h4>
                <ul class="support__list">
                  <li>{{ $filters.localizeFilter('support_subtext3-1') }}</li>
                  <li>{{ $filters.localizeFilter('support_subtext3-2') }}</li>
                </ul>
              </div>
            </div>
          </div>

          <ul v-else class="supportsm__tabs">
            <li>
              <h4 class="supportsm__title" :class="{ supportsm__hide: item1 }" @click="toggleBlock(1)">
                {{ $filters.localizeFilter('support_subtitle1') }}
              </h4>
              <div class="supportsm__cont">
                <img src="@/assets/supportsm__study-videos.svg" alt="study videos" class="supportsm__img" />
                <p class="supportsm__text1">
                  {{ $filters.localizeFilter('support_subtext1') }}
                </p>
                <a href="//www.youtube.com/channel/UCv6MaaPIPZg8qRGYYU4DKzw" target="_blank" class="support__btn-yt">
                  <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24.7909 3.46342C24.4992 2.39628 23.6442 1.55507 22.56 1.26772C20.5792 0.734375 12.6559 0.734375 12.6559 0.734375C12.6559 0.734375 4.73296 0.734375 2.75218 1.2475C1.68879 1.53454 0.812954 2.39643 0.521267 3.46342C0 5.41253 0 9.45471 0 9.45471C0 9.45471 0 13.5173 0.521267 15.446C0.813263 16.513 1.66793 17.3542 2.75233 17.6416C4.75382 18.175 12.6562 18.175 12.6562 18.175C12.6562 18.175 20.5792 18.175 22.56 17.6619C23.6444 17.3747 24.4992 16.5335 24.7912 15.4665C25.3123 13.5173 25.3123 9.47524 25.3123 9.47524C25.3123 9.47524 25.3332 5.41253 24.7909 3.46342V3.46342ZM10.1333 13.189V5.7204L16.7219 9.45471L10.1333 13.189Z"
                      fill="#BCCADC"
                    />
                  </svg>
                  youtube_traforce</a
                >
              </div>
            </li>
            <li>
              <h4 class="supportsm__title " :class="{ supportsm__hide: item2 }" @click="toggleBlock(2)">
                {{ $filters.localizeFilter('support_subtitle2') }}
              </h4>
              <div class="supportsm__cont">
                <ol class="supportsm__list">
                  <li>{{ $filters.localizeFilter('support_subtext2-1') }}</li>
                  <li>{{ $filters.localizeFilter('support_subtext2-2') }}</li>
                </ol>
                <img src="@/assets/supportsm__helps.svg" alt="helps" class="supportsm__img" />
              </div>
            </li>
            <li>
              <h4 class="supportsm__title" :class="{ supportsm__hide: item3 }" @click="toggleBlock(3)">
                {{ $filters.localizeFilter('support_subtitle3') }}
              </h4>
              <div class="supportsm__cont">
                <ol class="supportsm__list">
                  <li>{{ $filters.localizeFilter('support_subtext3-1') }}</li>
                  <li>{{ $filters.localizeFilter('support_subtext3-2') }}</li>
                </ol>
                <img src="@/assets/supportsm__free-lp-creatives.svg" alt="free lp, creatives" class="supportsm__img" />
              </div>
            </li>
          </ul>
          <!-- end -->
        </div>
      </div>
    </div>

    <SupportTel />
    <SupprotCommunity />
  </section>
</template>

<script>
import SupportTel from '@/views/SupportTel';
import SupprotCommunity from '@/views/SupportCommunity';
export default {
  data() {
    return {
      width: window.innerWidth,
      item1: false,
      item2: true,
      item3: true
    };
  },
  methods: {
    toggleBlock(number) {
      switch (number) {
        case 1:
          this.item1 = !this.item1;
          break;
        case 2:
          this.item2 = !this.item2;
          break;
        case 3:
          this.item3 = !this.item3;
          break;
      }
    }
  },
  components: {
    SupportTel,
    SupprotCommunity
  },
  computed: {
    imgType(){
      let currL = this.$store.getters.lang || 'ru'
      
      return currL == 'en' ? require('@/assets/g284.svg') : require('@/assets/support__img2.svg')
    }
  }
};
</script>

<style lang="scss" scoped>
#support {
  .title {
    margin-bottom: 12px;
    margin-bottom: 22px;
  }
  .support__text1 {
    font: 600 0.875rem/1.3 $font;
    color: $grey;
    margin-bottom: 48px;
    margin-bottom: 58px;
  }
}

.support__col {
  padding: 0;
}

.support__widecont {
  width: 100%;
  max-width: 100%;
  position: relative;
  &.left {
    &.support__middle {
      margin-bottom: 145px;
      .support__imgs {
        width: 62%;
        top: -65px;
        right: 0;
      }
      .support__contin {
        margin-right: auto;
        padding: 45px 55% 45px 45px;
      }
    }
  }
  &.right {
    &.support__top {
      margin-bottom: 112px;
      .support__imgs {
        width: 56%;
        top: -15px;
        left: 0;
      }
      .support__contin {
        margin-left: auto;
        padding: 9px 40px 23px 55%;
      }
    }
    &.support__bottom {
      margin-bottom: 115px;
      .support__imgs {
        width: 56%;
        top: -60px;
        left: 0;
      }
      .support__contin {
        width: 38%;
        margin-left: auto;
        padding: 35px 70px 40px 60%;
      }
    }
  }
  .support__imgs {
    display: block;
    width: 56%;
    position: absolute;
    top: -15px;
    z-index: 2;
  }
}
.support__contin {
  width: 31%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  position: relative;
  z-index: 1;

  .support__subtitle {
    font: bold 1rem/1.3 $font;
    color: $grey2;
    margin-bottom: 13px;
  }
  .support__text2 {
    font: 600 0.875rem/1.3 $font;
    color: $grey;
    margin-bottom: 10px;
  }

  .support__list {
    li {
      padding-left: 17px;
      font: 600 0.87rem/1.3 $font;
      color: $grey;
      position: relative;
      margin-bottom: 8px;
      &::before {
        content: url(../assets/pentagon.svg);
        position: absolute;
        left: 0;
        top: 0px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
// btn
.support__btn-yt {
  width: 220px;
  display: flex;
  justify-content: space-around;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #e79c84;
  background: linear-gradient(172.11deg, #e9eff7 13.49%, #e2e8f0 97.05%);
  box-shadow: 7px 7px 17px rgba(188, 202, 220, 0.7), -7px -7px 17px rgba(243, 244, 247, 0.9);
  border-radius: 9px;
  padding: 13px;
  font: bold 0.75rem/1.37 $font;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: $grey;
  transition: all 0.25s;
  svg {
    margin-right: 10px;
    path {
      fill: $grey3;
      transition: all 0.25s;
    }
  }
  &:hover {
    border: 1px solid transparent;
    background: linear-gradient(147.5deg, #e9eff7 13.49%, #e2e8f0 97.05%);
    box-shadow: inset 7px 6px 17px #c6d2e2, inset -16px -16px 13px rgba(255, 255, 255, 0.46);
    transition: all 0.25s;
    svg {
      path {
        fill: red;
        transition: all 0.25s;
      }
    }
  }
}

// adaptive
@media (max-width: 1200.98px) {
  .support__widecont {
    width: 100%;
    &.left {
      &.support__middle {
        margin-bottom: 145px;
        margin-bottom: 95px;
        .support__imgs {
          width: 62%;
          top: -10px;
          right: 0;
        }
        .support__contin {
          margin-right: auto;
          padding: 45px 55% 45px 45px;
        }
      }
    }
    &.right {
      &.support__top {
        margin-bottom: 55px;
        .support__imgs {
          width: 56%;
          top: 15px;
          left: 0;
        }
        .support__contin {
          margin-left: auto;
          padding: 9px 40px 23px 55%;
        }
      }
      &.support__bottom {
        margin-bottom: 95px;
        .support__imgs {
          width: 56%;
          top: -30px;
          left: 0;
        }
        .support__contin {
          width: 100%;
          margin-left: auto;
          padding: 35px 70px 40px 60%;
        }
      }
    }
    .support__imgs {
      width: 56%;
      position: absolute;
      top: -15px;
    }
  }
}
@media (max-width: 992.98px) {
  #support {
    .title {
      line-height: 1.37rem;
      span {
        display: block;
      }
    }
    .title,
    .support__text1 {
      margin-bottom: 14px;
      text-align: center;
    }
  }
  .support__col {
    padding: 0 15px;
  }
  .supportsm__tabs {
    li {
      margin-bottom: 10px;
      background: rgba(255, 255, 255, 0.2);
      // background: rgba(255, 0, 0, 0.2);
      mix-blend-mode: normal;
      border-radius: 15px;
      padding: 12px 10px 20px;
      padding: 12px 10px;
      overflow: hidden;
      .supportsm__title {
        font: bold 1rem/1.3 $font;
        color: $grey2;
        margin-bottom: 13px;
        padding-right: 15px;
        position: relative;
        transition: 0.3s all;
        &:before {
          content: '';
          display: block;
          width: 9px;
          height: 9px;
          border-left: 2px solid black;
          border-bottom: 2px solid black;
          position: absolute;
          right: 5px;
          transform: rotate(135deg);
          top: calc(50% - 5px);
          transition: all 0.25s;
        }
        &.supportsm__hide {
          margin-bottom: 0;
          &:before {
            transform: rotate(-45deg);
            top: calc(50% - 5px);
            transition: all 0.25s;
          }
          & + .supportsm__cont {
            // display: none;
            height: 0px;
            max-height: 0px;
            overflow: hidden;
            transition: max-height 0.93s ease-out;
          }
        }
      }
      .supportsm__cont {
        height: auto;
        max-height: 1500px;
        transition: max-height 0.93s ease-out;
      }
      .supportsm__img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }
      .supportsm__text1 {
        font: 600 0.875rem/1.3 $font;
        text-align: center;
        color: $grey;
        margin-bottom: 10px;
      }
      .support__btn-yt {
        margin: 0 auto;
        box-shadow: 7px 7px 17px rgba(188, 202, 220, 0.7), -7px -7px 17px rgba(243, 244, 247, 0.9);
      }
      .supportsm__list {
        margin-bottom: 15px;
        li {
          background: transparent;
          border-radius: 0;
          padding: 0;

          padding-left: 17px;
          font: 600 0.87rem/1.3 $font;
          color: $grey;
          position: relative;
          margin-bottom: 8px;
          &::before {
            content: url(../assets/pentagon.svg);
            position: absolute;
            left: 0;
            top: 0px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 768.98px) {
}
@media (max-width: 576.98px) {
  #support {
    .title {
      // font-size: 1rem;
    }
  }

  .supportsm__tabs {
    li {
      margin-bottom: 10px;
      padding: 12px 10px;
      .supportsm__title {
        // min-height: 50px;
        font: bold 0.87rem/1.3 $font;
        margin-bottom: 13px;
        padding-right: 15px;
        &:before {
          top: calc(50% - 5px);
        }
        &.supportsm__hide {
          &:before {
            top: calc(50% - 5px);
          }
        }
      }
      .supportsm__cont {
        max-height: 1500px;
      }
      .supportsm__text1 {
        font: 600 0.875rem/1.3 $font;
        margin-bottom: 10px;
      }
      .support__btn-yt {
        margin: 0 auto;
      }
      .supportsm__list {
        margin-bottom: 15px;
        li {
          padding-left: 17px;
          font: 600 0.87rem/1.3 $font;
          margin-bottom: 8px;
          &::before {
            left: 0;
            top: 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 320.98px) {
}
</style>
