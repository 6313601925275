import Repository from './Repository'

// const resource = '/sales'
const resource = '/custom/banners'

export default {
    get() {
        return Repository.get(`${resource}`)
    }
}
