import Repository from './Repository'

// const resource = '/sales'
const resource = '/custom/register'
const resource_valid = '/custom/register_valid'

export default {
    get(data) {
        return Repository.get(`${resource}`, {params:data})
    },
    validate(data) {
        return Repository.get(`${resource_valid}`, {params:data})
    }
}
