<template>
  <div class="container community">
    <div class="support__community">
      <div class="support__left">
        <h3 class="support__subtitle" v-html="$filters.localizeFilter('support_title')"></h3>

        <ul class="support__list">
          <li>{{ $filters.localizeFilter('support_text51') }}</li>
          <li>{{ $filters.localizeFilter('support_text52') }}</li>
        </ul>

        <!-- <img src="@/assets/support__dialog-sm.svg" alt="dialog" class="support__dialog sm" /> -->
        <!-- <img src="@/assets/q_a.gif" alt="dialog" class="support__dialog sm" /> -->
        <img src="@/assets/q_a.gif" alt="dialog" class="support__dialog sm" />

        <a href="//t.me/dating_traf" target="_blank" class="suppor__btn btn-white">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                d="M9.41693 15.1814L9.01994 20.7654C9.58794 20.7654 9.83393 20.5214 10.1289 20.2284L12.7919 17.6834L18.3099 21.7244C19.3219 22.2884 20.0349 21.9914 20.3079 20.7934L23.9299 3.82141L23.9309 3.82041C24.2519 2.32441 23.3899 1.73941 22.4039 2.10641L1.11393 10.2574C-0.339066 10.8214 -0.317066 11.6314 0.866934 11.9984L6.30993 13.6914L18.9529 5.78041C19.5479 5.38641 20.0889 5.60441 19.6439 5.99841L9.41693 15.1814Z"
                fill="#BCCADC"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          @Dating-traf
        </a>
      </div>

      <div class="support__right">
        <img :src="imgType" alt="dialog" class="support__dialog lg" data-aos="fade-left" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

  computed: {
    imgType(){
      let currL = this.$store.getters.lang || 'ru'
      
      return currL == 'en' ? require('@/assets/g_a_en.gif') : require('@/assets/q_a.gif')
    }
  }
};
</script>

<style lang="scss" scoped>
.community {
  padding: 100px 15px;
  padding: 100px 15px 50px;
}
.support__community {
  display: flex;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
  .support__left {
    margin-right: 42px;
    margin-right: 0;
    .support__subtitle {
      font: bold 1rem/1.3 $font;
      color: $grey2;
      margin-bottom: 15px;
    }
    .support__list {
      margin-bottom: 28px;
      li {
        font: bold 0.875rem/1.3 $font;
        color: $grey;
        padding-left: 15px;
        position: relative;
        &::before {
          content: url(../assets/pentagon.svg);
          position: absolute;
          left: 0;
          top: -1px;
        }
      }
    }
    .suppor__btn {
      justify-content: center;
      margin-bottom: 0;
      svg {
        margin-right: 12px;
        path {
          transition: all 0.25s;
        }
      }
      &:hover {
        svg path {
          fill: $tel;
          transition: all 0.25s;
        }
      }
    }
  }
  .support__right {
    width: 60%;
  }
}
.support__dialog.lg {
  // display: block;
  max-width: 100%;
}
.support__dialog.sm {
  display: none;
}

@media (max-width: 1200.98px) {
}
@media (max-width: 992.98px) {
  .support__community {
    justify-content: center;
    align-items: center;
    .support__left {
      margin-right: 42px;
      .support__subtitle {
        font: bold 1rem/1.3 $font;
        margin-bottom: 15px;
      }
      .support__list {
        margin-bottom: 28px;
        li {
          font: bold 0.875rem/1.3 $font;
          padding-left: 15px;
        }
      }
      .suppor__btn {
        justify-content: center;
        margin-bottom: 0;
        svg {
          margin-right: 12px;
        }
      }
    }
  }
  .support__dialog.lg {
    display: block;
    max-width: 100%;
  }
}
@media (max-width: 768.98px) {
  .community {
    padding: 55px 15px 46px;
  }
  .support__community {
    justify-content: center;
    align-items: center;
    .support__left {
      margin-right: 0px;
      margin: 0 auto;
      .support__subtitle {
        font: bold 1rem/1.3 $font;
        margin-bottom: 15px;
      }
      .support__list {
        margin-bottom: 18px;
        li {
          font: bold 0.875rem/1.3 $font;
        }
      }
      .suppor__btn {
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 0;
        svg {
          margin-right: 12px;
        }
      }
    }
    .support__right {
      display: none;
    }
  }

  .support__dialog.sm {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 18px;
  }
  .support__dialog.lg {
    display: none;
  }
}
@media (max-width: 576.98px) {
}
@media (max-width: 320.98px) {
}
</style>
