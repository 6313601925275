import salesRepository from './salesRepository'
import userRepository from './userRepository'
import reviewRepository from './reviewRepository'

const repositories = {
    sales: salesRepository,
    user: userRepository,
    review: reviewRepository
}

export const RepositoryFactory = {
    get: name => repositories[name]
}