import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import store from './store'
// CSS
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'swiper/css';
// mixins
import modals from '@/mixins/modals.mixin.js'
import generals from '@/mixins/generals.mixin.js'
// filters
import localizeFilter from '@/filters/localization.filter.js'

// modal component
import '@/styles/modal.css'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue3 from 'bootstrap-vue-3'
import router from '@/router'

// ANIMATIONS
import 'animate.css/animate.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

class AosPlugin {
  config = {
    easing: 'ease-out-back',
    duration: 1000,
    offset: 200,
    mirror: false,
    disable: function() {
      return window.innerWidth < 992;
    }
  }

  install(app) {
    AOS.init(this.config)

    app.mixin({
      updated() {
        this.$nextTick(function () {
          AOS.refreshHard() // This is needed to avoid the un-animate aos effect
        })
      }
    })
  }
}

const Vue = createApp(App)

// Use
Vue.use(new AosPlugin())
Vue.mixin(generals)
Vue.config.globalProperties.$filters = { localizeFilter }
Vue.config.globalProperties.$store = store
Vue.use(BootstrapVue3)
Vue.use(router)
Vue.use(modals)

Vue.mount('#app')
