import store from '@/store/'
import ru from '@/lang/ru.json'
import ua from '@/lang/ua.json'
import en from '@/lang/en.json'

const locales = {
  'ru': ru,
  'ua': ua,
  'en': en,
}

export default function(key) {
  const currentLang = store.getters.lang || 'ua'
  return locales[currentLang][key] || `[localize error]: ${key} - need translate`
}
