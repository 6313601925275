import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const SalesRepository = RepositoryFactory.get('sales');

export default {
  state: {
    defaultTemplate: {
      isDefault: true,
      banner: {
        isDefault: true,
        url: {
          ru: null,
          en: null
        },
        icon: {
          ru: '/img/sales__icon.svg?1',
          en: '/img/sales__icon.svg?1'
        },
        description: {
          ru: "У нас максимально простая и быстрая регистрация без лишних собеседований и пруфов...",
          ua: 'В нас <span class="in">максимально проста та швидка реєстрація</span> без зайвих знань та доказів...',
          en: 'We have <span class="in">maximum easy and quick registration</span> without less aquintance and proves...'
        }
      },
      popup: {
        icon: {
          ru: '/img/sales__icon.svg?1',
          ua: '/img/sales__icon.svg?1',
          en: '/img/sales__icon.svg?1'
        },
        description: {
          ru: "У нас <span class='in'>максимально простая и быстрая регистрация</span> без лишних собеседований и пруфов... ",
          ua: 'We have ...',
          en: 'We have ...'
        },
        button: {
          link: "//google.com/",
          name: {
            ru: "Узнать подробнее",
            en: "More"
          }
        }
      }
    },
    saleData: null
  },
  mutations: {
    setSaleData(state, payload) {
      state.saleData = payload
    }
  },
  actions: {
    async fetchSalesData({ commit }) {
      try {
        const res = await SalesRepository.get();
        commit('setSaleData', res.data);
        return res;
      } catch (error) {
        return false;
        // console.log(error)
      }
    },
  },
}
