<template>
  <div id="app">
    <TopHeader />
    <router-view></router-view>
    <!-- Modals -->
    <BModalReg />
    <BModalInfo />
    <ToTop />
  </div>
</template>

<script>
import TopHeader from '@/views/Header';
import BModalReg from '@/components/BModalReg';
import BModalInfo from '@/components/BModalInfo';
import ToTop from '@/components/ToTop';

export default {
  name: 'App',
  components: {
    TopHeader,
    BModalReg,
    BModalInfo,
    ToTop
  }
};
</script>

<style lang="scss">
#app {
  width: 100%;
  min-width: 100vw;
  height: auto;
}
@import '@/styles/styles.scss';
</style>
