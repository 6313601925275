<template>
  <transition name="fade">
    <a href="#app" @click.prevent="scrollToElement" id="to-top-btn" v-show="isShow">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="284"
        height="284"
        viewBox="0 0 284.929 284.929"
        style="enable-background:new 0 0 284.929 284.929;"
        xml:space="preserve"
      >
        <path
          d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
        />
      </svg>
    </a>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      isShow: false
    };
  },
  methods: {
    redirectTo() {}
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.isShow = window.pageYOffset > 500 ? true : false;
    });
  }
};
</script>

<style lang="scss" scoped>
#to-top-btn {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 1000;
  background: $grey3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  svg {
    fill: white;
    width: 20px;
    transform: rotateX(180deg);
    transition: all 0.25s;
    pointer-events: none;
  }
  &:hover {
    background: $grey;
    svg {
      // transform: rotateX(180deg) scale(1.2);
      transition: all 0.25s;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
