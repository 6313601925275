<template>
  <main>
    <Slider />
    <Eggs />
    <SliderOffers />
    <Offers />
    <Support />
    <SliderRev />
    <Footer />
  </main>
</template>

<script>
import Slider from '@/views/Slider';
import Eggs from '@/views/Eggs';
import SliderOffers from '@/views/SliderOffers';
import Offers from '@/views/Offers';
import Support from '@/views/Support';
import SliderRev from '@/views/SliderRev';
import Footer from '@/views/Footer';

export default {
  components: {
    Slider,
    Eggs,
    SliderOffers,
    Offers,
    Support,
    SliderRev,
    Footer
  }
};
</script>

<style lang="scss">
main {
  width: 100%;
  margin: 0 auto;
  padding-top: 140px;
  padding-top: 120px;
}
@media (max-width: 992.98px) {
  main {
    padding-top: 90px;
  }
}
@media (max-width: 576.98px) {
  main {
    padding-top: 80px;
  }
}
</style>
