import Vuex from 'vuex'
import sales from './sales'

import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from 'vuex-shared-mutations'

export default new Vuex.Store({
  plugins: [ 
      createPersistedState({ storage: window.sessionStorage }),
      createMutationsSharer({ predicate: (mutation, state) => {
        const predicate = [
        ]
        return predicate.indexOf(mutation.type) >= 0;
    }})
  ],
  state: {
    lang: null,
    languages: ['ua', 'en', 'ru'],
    // saleData: null,
    isMenuOpened: false,
    isModalOpen: false,
    isModalInfoOpen: false,
    linkLogIn: '//affiliate.traforce.com/',
    isDev: false
  },
  getters: {
    lang(state){
      return state.lang
    }
  },
  mutations: {
    changeLang(state, newLang) {
      state.lang = newLang
    },
    changeOpenedMenu(state, payload) {
      state.isMenuOpened = typeof payload === 'boolean' ? payload : !state.isMenuOpened;
    },
    setDevStatus(state, status) {
      state.isDev = status == 'local' ? true : false
    }
  },
  actions: {
    devStatus({ commit }) {
      if(typeof(window.$settings) !== 'undefined') {
        console.log('variant', window.$settings['register_form_view'], window.$settings)
        commit('setDevStatus', window.$settings['register_form_view']);
      } else {
        commit('setDevStatus', 'local ');
        console.log('variant local default')
      }
      // if(location.hostname == 'traforce.com') status = false
      // else status = true
      
    }
  },
  modules: {
    sales
  }
})
