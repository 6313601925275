<template>
  <div class="offers__swipercont">
    <Swiper class="offers__swiper" ref="mySwiper" :key="swiperData" @slideChange="swiperChange" @onSwiper="onSwiper">
      <swiper-slide v-for="(slide, index) of slides" :key="(index + 1) * 99" @click="changeContent(index)">
        <div class="offers__item" :class="[`item-${index}`]">
          <img :src="slide.pagination.logoImg" alt="brand logo" class="offers__brand-logo" />
        </div>
        <!-- {{ index }} -->
      </swiper-slide>
    </Swiper>
    <button class="offers__btn prev " @click="moveSlide(1)">
      <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.932 7.01006L17.9616 7.01655L5.24771 7.01655L9.24451 3.01094C9.44022 2.81538 9.54758 2.55046 9.54758 2.27242C9.54758 1.99437 9.44022 1.7313 9.24451 1.53528L8.62261 0.913066C8.42705 0.717505 8.16645 0.609375 7.88856 0.609375C7.61051 0.609375 7.34976 0.716733 7.1542 0.912294L0.302923 7.76295C0.10659 7.95929 -0.000768219 8.22081 4.1385e-06 8.49901C-0.000768219 8.77876 0.10659 9.04043 0.302923 9.23646L7.1542 16.0877C7.34976 16.2831 7.61035 16.3907 7.88856 16.3907C8.16645 16.3907 8.42705 16.283 8.62261 16.0877L9.24451 15.4655C9.44022 15.2703 9.54758 15.0095 9.54758 14.7315C9.54758 14.4536 9.44022 14.2066 9.24451 14.0112L5.20261 9.98317H17.9462C18.5188 9.98317 19 9.48964 19 8.91732V8.0373C19 7.46498 18.5046 7.01006 17.932 7.01006Z"
          fill="#6C7D96"
        />
      </svg>
    </button>
    <button class="offers__btn next" @click="moveSlide(2)">
      <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.06801 9.98994L1.03835 9.98345L13.7523 9.98345L9.75549 13.9891C9.55978 14.1846 9.45242 14.4495 9.45242 14.7276C9.45242 15.0056 9.55978 15.2687 9.75549 15.4647L10.3774 16.0869C10.573 16.2825 10.8335 16.3906 11.1114 16.3906C11.3895 16.3906 11.6502 16.2833 11.8458 16.0877L18.6971 9.23705C18.8934 9.04071 19.0008 8.77919 19 8.50099C19.0008 8.22124 18.8934 7.95957 18.6971 7.76354L11.8458 0.912265C11.6502 0.716859 11.3896 0.609347 11.1114 0.609347C10.8335 0.609347 10.573 0.717013 10.3774 0.912266L9.75549 1.53448C9.55977 1.72973 9.45242 1.99048 9.45242 2.26853C9.45242 2.54642 9.55977 2.79342 9.75549 2.98883L13.7974 7.01683L1.0538 7.01683C0.481172 7.01683 -8.40573e-06 7.51036 -8.3557e-06 8.08268L-8.27876e-06 8.96271C-8.22873e-06 9.53502 0.495384 9.98994 1.06801 9.98994Z"
          fill="#6C7D96"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'OffersSliderSM',
  props: {
    slides: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 5000
        },
        loop: false,
        slidesPerView: 1,
        spaceBetween: -60
      }
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.swiper.params = Object.assign({}, this.swiper.params, this.swiperOptions)
      this.swiper.update();
    },
    swiperChange() {
      const instance = this.swiper;
      this.changeContent(+instance.realIndex);
    },
    changeContent(slideTo) {
      this.$emit('ChangeContent', slideTo);
    },
    moveSlide(val) {
      const instance = this.swiper;
      switch (val) {
        case 1:
          // prev
          instance.slidePrev();
          break;
        case 2:
          // next
          instance.slideNext();
          break;
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.offers__swipercont {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}
// Slider
.offers__swiper {
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0 auto;
  margin-bottom: -40px;
}
.swiper-container {
  overflow: none;
  padding: 30px 0 40px 0;
}
// slide
.swiper-slide {
  width: 70%;
  .offers__item {
    display: flex;
    width: 75%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    max-height: 70px;
    background: white;
    border-radius: 9px;
    opacity: 0.2;
    opacity: 0.8;
    padding: 20px 15px;
    margin-bottom: 25px;
    transition: all 0.25s;
    z-index: 2;
    .offers__brand-logo {
      display: block;
      max-width: 200px;
      max-height: 50px;
    }
  }
  &.swiper-slide-active {
    z-index: 3;
    .offers__item {
      opacity: 1;
      filter: drop-shadow(0px 13px 22px rgba(119, 155, 185, 0.68));
      transition: all 0.25s;
      z-index: 4;
      &.item-0 {
        background: white;
      }
      &.item-1 {
        background: #59bf40;
      }
      &.item-2 {
        background: #9a2e54;
      }
      &.item-3 {
        background: white;
      }
      &.item-4 {
        background: #294762;
      }
    }
  }
}

// btns
.offers__btn {
  border: none;
  background: transparent;
  position: absolute;
  top: calc(50% - 11px);
  z-index: 5;
  &:active {
    svg path {
      fill: $orange;
    }
  }
  &.prev {
    left: 0;
  }
  &.next {
    right: 0;
  }
}
@media (max-width: 992.98px) {
}
@media (max-width: 768.98px) {
}
@media (max-width: 576.98px) {
}
@media (max-width: 320.98px) {
}
</style>
